import React from "react";
import { Box, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { ReactComponent as DrugIcon } from "../../assets/drug_logo.svg";
import { ReactComponent as SyringeIcon } from "../../assets/syringe_logo.svg";
import { ReactComponent as GroupSmallIcon } from "../../assets/Group_small.svg";
import { ReactComponent as MedicalIcon } from "../../assets/Medical_logo.svg";
import { ReactComponent as StethescopeIcon } from "../../assets/stethescope_logo.svg";
import { useNavigate } from "react-router-dom";

const NewAuthTypeContent = (props) => {

  const { patient } = props;
  const selectedPatient = patient &&  [patient.memberId, patient.lastName, patient.firstName, patient.mrn];
  const navigate = useNavigate();

  const handlePriorAuthType = authType => {
    localStorage.setItem("authType", authType);
    localStorage.setItem("selectedPatient", selectedPatient[0]);
    navigate("/priorAuth");
  }
  const handleEncounter = () => {
    localStorage.setItem("selectedPatient", selectedPatient[0]);
    navigate("/encounter");
  }

  return (
      <>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 12 }}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Last Name: {selectedPatient[1]}
          </Typography>
          <Typography variant="h6" sx={{ color: "white" }}>
            First Name: {selectedPatient[2]}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-start", gap: 13.5 }}
        >
          <Typography variant="h6" sx={{ color: "white" }}>
            MRN: {selectedPatient[3]}
          </Typography>
          <Typography variant="h6" sx={{ color: "white" }}>
            Member ID: {selectedPatient[0]}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" sx={{ color: "white" }}>
        Select the type of prior authorization
      </Typography> */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Card
          component={Button}
          sx={{
            display: "grid",
            justifyContent: "center",
            backgroundColor: "rgba(61, 61, 166, 0.5)",
            borderRadius: 15,
            minWidth: "175px",
            "&:hover" : {
              backgroundColor: "rgba(61, 61, 166, 0.5)"
            }
          }}
          onClick={() => handlePriorAuthType("Order")}
        >
          <Typography variant="body1" sx={{ color: "white" }}>
            Order
          </Typography>
          <CardContent sx={{ display: "flex", gap: 3 }}>
            <DrugIcon />
            <SyringeIcon />
          </CardContent>
        </Card>
        <Card
          component={Button}
          sx={{
            display: "grid",
            justifyContent: "center",
            backgroundColor: "rgba(61, 61, 166, 0.5)",
            borderRadius: 15,
            minWidth: "175px",
            "&:hover" : {
              backgroundColor: "rgba(61, 61, 166, 0.5)"
            }
          }}
          onClick={() => { handleEncounter() }}
        >
          <Typography variant="body1" sx={{ color: "white" }}>
            Encounter
          </Typography>
          <CardContent sx={{ display: "flex", gap: 3 }}>
            <GroupSmallIcon />
            <MedicalIcon />
            <StethescopeIcon />
          </CardContent>
        </Card>
      </Box>
     {/* </CardContent> */}
    </>
  );

}

export default NewAuthTypeContent;