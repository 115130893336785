import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { facilities } from "../../util/constants";
import { fetchProcedureCodes } from "../../api/api";
import Procedures from "./Procedures";
import { priorAuthStyles } from "./PriorAuthStyles";
import Organization from "./Organization";

const StepperContent = (props) => {
  // const [procedureCodes, setprocCodes] = useState([]);

  // const fetchProcedureList = async () => {
  //   const data = await fetchProcedureCodes();
  //   setprocCodes(data);
  // };

  // useEffect(() => {
  //   fetchProcedureList();
  // }, []);

  const {
    step,
    value,
    handleChange,
    showProcedures,
    patient,
    provider,
    selectedProc,
    setSelectedProc,
    selectedOrg,
    setSelectedOrg,
    organizationCodes,
    showOrderSelect,
    handleCRDrequest,
    crdResponse,
    appointmentDate,
    setAppointmentDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    appointmentType,
    setAppointmentType,
    setSelectedHook,
    setShowForm,
    setShowProcedures,
    checked,
    handleCheckBoxChange,
    procedureCodes,
  } = props;
  // const authType = localStorage.getItem("authType");
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      {step === 0 && (
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: 30,
            flexDirection: "row",
            m: "2%",
            minWidth: "70vw",
            minHeight: "50vh",
            backgroundColor: "inherit",
            border: "1px solid white",
            p: 1,
            "@media (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          {patient?.message || Object.keys(patient).length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                p: "1%",
                flexDirection: "column",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  lineHeight: "10px",
                }}
              >
                {Object.keys(patient).length === 0 ? (
                  <CircularProgress size={50} />
                ) : (
                  <>
                    <p>{patient.message.split(":")[0]}</p>
                    <h2>{patient.message.split(":")[1]}</h2>
                  </>
                )}
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                ...priorAuthStyles.flexStartDiv,
                ...priorAuthStyles.columnDiv,
                alignContent: "center",
                p: 2,
              }}
            >
              <Grid container spacing={4}>
                {[
                  { label: "First Name", value: patient.firstName },
                  // { label: "Height", value: patient.height },
                  { label: "Last Name", value: patient.lastName },
                  // { label: "Weight", value: patient.weight },
                  { label: "Date of Birth", value: patient.dob },
                  { label: "Insurance Name", value: patient.insuranceName },
                  { label: "Gender", value: patient.gender },
                  {
                    label: "Policy ID",
                    value: patient.policyId || "Not Found",
                  },
                  { label: "Address", value: patient.address },
                  { label: "Group ID", value: patient.groupId || "Not Found" },
                  { label: "City", value: patient.city },
                  { label: "Member ID", value: patient.memberId },
                  {
                    label: "State, Zip",
                    value: `${patient.state || "N/A"}, ${patient.zip || "N/A"}`,
                  },
                ].map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box
                      display="flex"
                      gap={1}
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          sx={{ ...priorAuthStyles.inputColor }}
                        >
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="h6"
                          sx={{ ...priorAuthStyles.inputColor }}
                        >
                          :
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="h6"
                          sx={{ ...priorAuthStyles.inputColor }}
                        >
                          {item.value || "N/A"}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Paper>
      )}
      {step === 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 5, md: 0 },
            ml: { xs: "5%", md: "0%" },
            mt: "3%",
            mb: "3%",
          }}
        >
          <Box
            sx={{
              ...priorAuthStyles.flexDiv,
              ...priorAuthStyles.columnDiv,
              gap: { xs: 5, md: 5 },
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography variant="h6">Requesting Provider</Typography>
            <Card
              sx={{
                borderRadius: 10,
                border: "1px solid white",
                backgroundColor: "inherit",
                minWidth: { xs: "100%", md: "75%" },
              }}
            >
              <CardContent
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <table>
                  {[
                    { label: "Name", value: provider.name },
                    { label: "NPI", value: provider.npi },
                    { label: "Phone", value: provider.phone },
                    { label: "Fax", value: provider.fax },
                    // { label: "TIN", value: provider.tin },
                    { label: "Email", value: provider.email },
                    { label: "Address", value: provider.address },
                  ].map((item, index) => (
                    <tr style={{ height: "60px" }} key={index}>
                      <td>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          {item.label}
                        </Typography>
                      </td>
                      <td style={{ color: "white", paddingLeft: 15 }}>:</td>
                      <td>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "white",
                            pl: 2,
                            ml: 2,
                            borderBottom: "1px solid white",
                          }}
                        >
                          {item.value ?? "N/A"}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </table>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              ...priorAuthStyles.flexDiv,
              ...priorAuthStyles.columnDiv,
              gap: { xs: 5, md: 5 },
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography variant="h6">Rendering Provider</Typography>
            <Card
              sx={{
                borderRadius: 10,
                border: "1px solid white",
                backgroundColor: "inherit",
                minWidth: { xs: "100%", md: "75%" },
              }}
            >
              <FormControlLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& .MuiStack-root": {
                    ...priorAuthStyles.rowDiv,
                  },
                }}
                required
                control={
                  <Checkbox
                    color="warning"
                    sx={{ color: "white" }}
                    checked={checked}
                    onChange={() => handleCheckBoxChange()}
                  />
                }
                label={
                  <Typography variant="body1" sx={{ color: "white" }}>
                    Same as Requesting Provider
                  </Typography>
                }
              />
              <CardContent sx={{ ...priorAuthStyles.flexStartDiv }}>
                <table>
                  {[
                    { label: "Name", value: provider.name },
                    { label: "NPI", value: provider.npi },
                    { label: "Phone", value: provider.phone },
                    { label: "Fax", value: provider.fax },
                    // { label: "TIN", value: provider.tin },
                    { label: "Email", value: provider.email },
                    { label: "Address", value: provider.address },
                  ].map((item, index) => (
                    <tr style={{ height: "60px" }} key={index}>
                      <td>
                        <Typography
                          variant="h6"
                          sx={{ ...priorAuthStyles.inputColor }}
                        >
                          {item.label}
                        </Typography>
                      </td>
                      <td
                        style={{
                          ...priorAuthStyles.inputColor,
                          paddingLeft: 15,
                        }}
                      >
                        :
                      </td>
                      <td>
                        <Typography
                          variant="h6"
                          sx={{
                            ...priorAuthStyles.inputColor,
                            pl: 2,
                            ml: 2,
                            borderBottom: "1px solid white",
                            minWidth: "150px",
                          }}
                        >
                          {checked ? item.value ?? "N/A" : ""}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </table>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {/* {step === 2 && (
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: 30,
            flexDirection: "row",
            m: "2%",
            minWidth: "70vw",
            //   minHeight: "50vh",
            backgroundColor: "inherit",
            border: "1px solid white",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start", p: 2 }}>
            <table>
              <Grid container spacing={4}>
                {[
                  { label: "Name", value: facilities[0].name },
                  { label: "Fax", value: facilities[0].fax },
                  { label: "Phone", value: facilities[0].phone },
                  { label: "NPI", value: facilities[0].npi },
                  { label: "Address", value: facilities[0].address },
                  { label: "TIN", value: facilities[0].tin },
                ].map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box display="flex" gap={1} sx={{ ...priorAuthStyles.inputColor }}>
                      <Typography variant="h6">{item.label}:</Typography>
                      <Typography variant="h6" sx={{
                        ...priorAuthStyles.inputColor,
                        pl: 1,
                        ml: 1,
                      }}>
                        {item.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </table>
          </Box>
        </Paper>
      )} */}
      {step === 2 && (
        <Paper
          variant="outlined"
          sx={{
            ...priorAuthStyles.stepperContentPaper_3,
            "@media (max-width: 1200px)": {
              width: "60%",
            },
            "@media (max-width: 700px)": {
              width: "30%",
            },
          }}
        >
          <Box sx={{ p: "1rem" }}>
            <Organization
              selectedOrg={selectedOrg}
              setSelectedOrg={setSelectedOrg}
              organizationCodes={organizationCodes}
            />
          </Box>
        </Paper>
      )}
      {step === 3 && (
        <Paper
          variant="outlined"
          sx={{
            ...priorAuthStyles.stepperContentPaper_3,
            "@media (max-width: 1200px)": {
              width: "60%",
            },
            "@media (max-width: 700px)": {
              width: "30%",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center", color: "white" }}
          >
            Select one among the below types
          </Typography>
          <Box sx={{ p: "1rem" }}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label2"
                name="radio-buttons-group1"
                value={value}
                onChange={handleChange}
                sx={{
                  ...priorAuthStyles.inputColor,
                  justifyContent: "space-evenly",
                  "& .MuiButtonBase-root": {
                    padding: { xs: 1, sm: 2 },
                  },
                }}
              >
                {Array.from(
                  new Set(procedureCodes.map((option) => option.category))
                ).map((category) => {
                  const option = procedureCodes.find(
                    (item) => item.category === category
                  );
                  return (
                    <FormControlLabel
                      key={option.code}
                      value={option.category}
                      control={
                        <Radio color="warning" sx={{ color: "white" }} />
                      }
                      label={
                        <Typography variant="body1" sx={{ color: "white" }}>
                          {option.category}
                        </Typography>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>

            {showProcedures && (
              <Procedures
                patient={patient}
                provider={provider}
                selectedProc={selectedProc}
                setSelectedProc={setSelectedProc}
                showOrderSelect={showOrderSelect}
                value={value}
                handleCRDrequest={handleCRDrequest}
                crdResponse={crdResponse}
                appointmentDate={appointmentDate}
                setAppointmentDate={setAppointmentDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                appointmentType={appointmentType}
                setAppointmentType={setAppointmentType}
                procedureCodes={procedureCodes}
                setSelectedHook={setSelectedHook}
                setShowForm={setShowForm}
                setShowProcedures={setShowProcedures}
              />
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default StepperContent;
