import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const commonStyles = {
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#d0d6f5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#d0d6f5",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#d0d6f5",
  },
  "& .MuiSvgIcon-root": {
    color: "#d0d6f5",
  },
};

const DashboardFilters = (props) => {
  const {
    setOpen,
    setSearchParam,
    filterOpen,
    handleOpen,
    handleFilterTypeChange,
    filterType,
    handleFilterValueChange,
    filterValues,
    handleApplyFilter,
    handleClearFilter,
  } = props;

  const handleNewAuth = async () => {
    setOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: "2%",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
          */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "inherit",
          padding: "4px 10px",
          borderRadius: "30px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          border: "2px solid #ABD9D9",
          cursor: "pointer",
        }}
        onClick={handleOpen}
      >
        {/* <Box sx={{display:'flex', alignItems: 'center', justifyContent:'center'}}> */}
        <IconButton>
          <FilterAltOutlinedIcon sx={{ color: "white", fontSize: "22px" }} />
        </IconButton>
        <Typography
          variant="subtitle1"
          sx={{ color: "white", marginLeft: "-5px", fontSize: "18px" }}
        >
          {filterOpen ? null : "Filter"}
        </Typography>
        {/* </Box> */}

        <form onSubmit={handleApplyFilter}>
          {filterOpen && (
            <FormControl
              variant="standard"
              sx={{
                minWidth: 150,
                marginRight: 1,
                "& .MuiInputBase-root": {
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                },

                ...commonStyles,
              }}
            >
              <Select
                value={filterType}
                onChange={handleFilterTypeChange}
                sx={{
                  fontSize: 18,
                }}
                required
              >
                <MenuItem value="patientname">Patient Name</MenuItem>
                <MenuItem value="memberId">Member Id</MenuItem>
                <MenuItem value="gender">Gender</MenuItem>
                <MenuItem value="dateofbirth">Date of birth</MenuItem>
              </Select>
            </FormControl>
          )}

          {filterOpen && filterType === "patientname" && (
            <TextField
              placeholder="Search by Name"
              value={filterValues.patientname}
              onChange={handleFilterValueChange}
              variant="standard"
              name="patientname"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "white", fontSize: "18px" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                ...commonStyles,
                marginTop: "-2px",
                flexGrow: 1,
                marginRight: "16px",
                width: 180,
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "16px",
                },
              }}
            />
          )}
          {filterOpen && filterType === "memberId" && (
            <TextField
              placeholder="Search by Member Id"
              value={filterValues.memberId}
              onChange={handleFilterValueChange}
              variant="standard"
              name="memberId"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "white", fontSize: "18px" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                ...commonStyles,
                marginTop: "-2px",
                flexGrow: 1,
                marginRight: "16px",
                width: 180,
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "16px",
                },
              }}
            />
          )}
          {filterOpen && filterType === "gender" && (
            <FormControl
              variant="standard"
              sx={{
                minWidth: 180,
                marginTop: "-2px",
                ...commonStyles,

                "& .MuiInputBase-root": {
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                },
              }}
              
            >
              {/* <InputLabel
                sx={{ color: "white", marginTop: -1.5, fontSize: 18 }}
              >
                Select Gender
              </InputLabel> */}
              <Select
                value={filterValues.gender}
                onChange={handleFilterValueChange}
                // label="Gender"
                name="gender"
                required
                displayEmpty
                renderValue={(value) => value ? value : <em style={{fontSize: '18px'}}>Choose a gender</em>}
                // placeholder="Choose a Gender"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          )}
          
          {filterOpen && filterType === "dateofbirth" && (
            <TextField
              autoFocus
              type="date"
              fullWidth
              variant="standard"
              value={filterValues.dateofbirth}
              onChange={handleFilterValueChange}
              sx={{
                width: 180,
                marginTop: "-2px",
                "& .MuiInputBase-root": {
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                },

                ...commonStyles,
              }}
              name="dateofbirth"
              required
            />
          )}

          {filterOpen && (
            <>
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  borderColor: "white",
                  color: "white",
                  marginRight: "8px",
                  marginLeft: "6px",
                  borderRadius: "20px",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "white",
                    backgroundColor: "#525783",
                  },
                }}
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                onClick={handleClearFilter}
                sx={{
                  backgroundColor: "#c1c1d1",
                  color: "#373b68",
                  borderRadius: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#a0a0b0",
                  },
                }}
              >
                Clear
              </Button>
            </>
          )}
        </form>
      </Box>

      {/* <Card
          variant="outlined"
          sx={{
            display: "flex",
            width: 320,
            justifyContent: "center",
            background: "inherit",
            border: "2px solid #ABD9D9",
            borderRadius: 20,
          }}
        >
          <FormControl sx={{ p: 1, width: "90%" }} variant="standard">
            <Input
              id="standard-adornment-password"
              placeholder="Search"
              type="text"
              sx={{ color: "white" }}
              color="warning"
              onChange={(e) => setSearchParam(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                  // onClick={() => setShowPassword(!showPassword)}
                  // onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    <SearchIcon sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Card> */}
      {/* </Box> */}

      {/* <Card
          variant="outlined"
          sx={{
            display: "flex",
            p: 1.2,
            width: 120,
            alignItems: "center",
            justifyContent: "center",
            background: "inherit",
            border: "2px solid #ABD9D9",
            borderRadius: 20,
          }}
        >
         <Typography variant="subtitle1" sx={{ color: "grey" }}>
            Filter
          </Typography>
          <IconButton sx={{ p: 0 }}>
            <FilterAltOutlinedIcon sx={{ color: "white" }} />
          </IconButton>
        </Card> */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          component={Button}
          variant="outlined"
          sx={{
            display: "flex",
            p: "0.7em 1.5em 0.7em 1.5em",
            alignItems: "center",
            justifyContent: "center",
            background: "inherit",
            border: "2px solid #ABD9D9",
            borderRadius: 20,
          }}
          onClick={handleNewAuth}
        >
          <AddOutlinedIcon sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ fontSize: "130%" }}>
            Add New Authorization
          </Typography>
        </Card>
      </Box> */}
    </Box>
  );
};

export default DashboardFilters;
