export const HeaderStyles = {
  header: { background: "#000235", minWidth: "100%", minHeight: "100vh" },
  shadow1: {
    background: "#8d8ddacc",
    display: "flex",
    pl: '20%',
    ml: '20%',
    width: 450,
    height: 200,
    filter: "blur(100px)",
    position: "fixed",
  },
  shadow2: {
    background: "#abd9d9cc",
    display: "flex",
    mt: 40,
    width: 50,
    float: "right",
    height: 400,
    filter: "blur(100px)",
    position: 'relative'
  },
};
