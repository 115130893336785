import React, { useState, useEffect, Fragment } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { class_data, status } from "../../util/constants";
import { fetchEnounterCodes, AddNewEncounter } from "../../api/api";
import { useNavigate } from "react-router-dom";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const AddEncounterModal = (props) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedStatus, setSelectedstatus] = useState("");
  const [encounterDate, setencounterDate] = useState(new Date().toString());

  const [reasonCodes, setReasonCodes] = useState([]);
  const [typeCodes, setTypeCodes] = useState([]);

  const [searchTypeCodes, setSearchTypeCode] = useState("");
  const [searchTypeDisplay, setSearchTypeDispaly] = useState("");
  const [selectedType, setselectedType] = useState({
    code: "",
    display: "",
  });

  const [searchReasonCodes, setSearchReasonCode] = useState("");
  const [searchReasonDisplay, setSearchReasonDisplay] = useState("");
  const [selectedReason, setselectedReason] = useState({
    code: "",
    display: "",
  });

  const [showEncounter, setShowEncounter] = useState(false);
  const [encounter_id, setEncounterId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event, value, reason) => {
    setSearchTypeCode(value);
  };
  const handleTypeDisplayChange = (event, value, reason) => {
    setSearchTypeDispaly(value);
  };
  const handleReasonChange = (event, value, reason) => {
    setSearchReasonCode(value);
  };
  const handleReasonDisplayChange = (event, value, reason) => {
    setSearchReasonDisplay(value);
  };

  const fetchProcedureList = async () => {
    const data = await fetchEnounterCodes();
    if (data.typeCodes) {
      setTypeCodes(data.typeCodes);
    }
    if (data.reasonCodes) {
      setReasonCodes(data.reasonCodes);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const pro_id = localStorage.getItem("pro_id");
    const patient_id = localStorage.getItem("selectedPatient");
    const req_body = {
      status: selectedStatus,
      typeCode: selectedType.code,
      typeDisplay: selectedType.display,
      class: selectedClass,
      period: encounterDate,
      reasonCode: selectedReason.code,
      reasonDisplay: selectedReason.display,
      patientID: patient_id,
      providerID: pro_id,
    };
    try {
      const res = await AddNewEncounter(user, req_body);
      if (res) {
        setEncounterId(res.id);
        sessionStorage.setItem("en_id", res.id);
        setShowEncounter(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProcedureList();
  }, []);

  const handleCancelClick = () => {
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      //   onClose={handleCancelClick}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#000235",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "white",
          backgroundColor: "rgb(61, 61, 166,0.4)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            flexGrow: 1,
          }}
        >
          New Encounter
        </Typography>
        <IconButton onClick={() => handleCancelClick()}>
          <HighlightOffOutlinedIcon sx={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {!showEncounter && (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="class" sx={{ color: "#c4c4c4" }}>
                    Class
                  </InputLabel>
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%", borderBottom: "2px solid white" }}
                  >
                    <Select
                      id="class"
                      fullWidth
                      required
                      value={selectedClass}
                      onChange={(e) => {
                        setSelectedClass(e.target.value);
                      }}
                      size="small"
                      sx={{ color: "white" }}
                    >
                      {class_data.map((data) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="status" sx={{ color: "#c4c4c4" }}>
                    Status
                  </InputLabel>
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%", borderBottom: "2px solid white" }}
                  >
                    <Select
                      id="status"
                      fullWidth
                      required
                      value={selectedStatus}
                      onChange={(e) => {
                        setSelectedstatus(e.target.value);
                      }}
                      size="small"
                      sx={{ color: "white" }}
                    >
                      {status.map((data) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="typeCodes" sx={{ color: "#c4c4c4" }}>
                    Type Code
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    required
                    size="small"
                    options={typeCodes ?? []}
                    value={selectedType}
                    onChange={(event, newValue) => {
                      setselectedType(
                        newValue ?? {
                          code: "",
                          display: "",
                        }
                      );
                    }}
                    inputMode="search"
                    loadingText="Loading Type Codes"
                    inputValue={searchTypeCodes}
                    onInputChange={handleInputChange}
                    getOptionLabel={(option) => option.code ?? ""}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        color={"secondary"}
                        focused
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="type-display" sx={{ color: "#c4c4c4" }}>
                    Type Code Display
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    required
                    size="small"
                    options={typeCodes ?? []}
                    value={selectedType}
                    onChange={(event, newValue) => {
                      setselectedType(
                        newValue ?? {
                          code: "",
                          display: "",
                        }
                      );
                    }}
                    inputMode="search"
                    loadingText="Loading Type Display"
                    inputValue={searchTypeDisplay}
                    onInputChange={handleTypeDisplayChange}
                    getOptionLabel={(option) => option.display ?? ""}
                    isOptionEqualToValue={(option, value) =>
                      option.display === value.display
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        color={"secondary"}
                        focused
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="reason-code" sx={{ color: "#c4c4c4" }}>
                    {" "}
                    Reason Code
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    required
                    size="small"
                    options={reasonCodes ?? []}
                    value={selectedReason}
                    onChange={(event, newValue) => {
                      setselectedReason(
                        newValue ?? {
                          code: "",
                          display: "",
                        }
                      );
                    }}
                    inputMode="search"
                    loadingText="Loading Reason Code"
                    inputValue={searchReasonCodes}
                    onInputChange={handleReasonChange}
                    getOptionLabel={(option) => option.code ?? ""}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        color={"secondary"}
                        focused
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="reason display"
                    sx={{ color: "#c4c4c4" }}
                  >
                    Reason Code Display
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    size="small"
                    required
                    options={reasonCodes ?? []}
                    value={selectedReason}
                    onChange={(event, newValue) => {
                      setselectedReason(
                        newValue ?? {
                          code: "",
                          display: "",
                        }
                      );
                    }}
                    inputMode="search"
                    loadingText="Loading Reason Display"
                    inputValue={searchReasonDisplay}
                    onInputChange={handleReasonDisplayChange}
                    getOptionLabel={(option) => option.display ?? ""}
                    isOptionEqualToValue={(option, value) =>
                      option.display === value.display
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        color={"secondary"}
                        focused
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel htmlFor="period" sx={{ color: "#c4c4c4" }}>
                    Period{" "}
                  </InputLabel>
                  <TextField
                    size="small"
                    variant="standard"
                    focused
                    required
                    color={"secondary"}
                    value={encounterDate}
                    onChange={(e) => {
                      const value = e.target.value;
                      setencounterDate(value);
                    }}
                    id="encounter-date"
                    fullWidth
                    type="date"
                    InputProps={{
                      style: { color: "white" },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                mt={2}
                sx={{ justifyContent: "flex-end", gap: "10px" }}
              >
                <Button
                  variant="text"
                  color="error"
                  onClick={handleCancelClick}
                >
                  <Typography>Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                  }}
                  type="submit"
                >
                  Create
                  {isLoading && (
                    <CircularProgress
                      sx={{ ml: ".5rem", color: "rgb(6 70 116)" }}
                      size={20}
                    />
                  )}
                </Button>
              </Grid>
            </form>
          </>
        )}
        {showEncounter && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: "white" }}>
                Encounter has been succesfully created: {encounter_id}
              </Typography>
            </Grid>
            <Grid container item xs={12} sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                }}
                onClick={() => {
                  navigate("/priorAuth");
                  handleCancelClick();
                }}
              >
                Proceed To Order
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default AddEncounterModal;
