import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as BackArrow } from "../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import RightMenu from "../RightMenu";

const PriorAuthHeader = () => {

    const navigate = useNavigate();
    const authType = localStorage.getItem("authType");

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 5}}>
         <Box sx={{display: 'flex', justifyContent: 'center', gap: 3}}>
            <IconButton onClick={() => navigate(-1)}>
                <BackArrow />
            </IconButton>
            <Typography variant="h6" sx={{letterSpacing: 7}}>ePA | {authType} Prior Authorization</Typography>
         </Box>
         <RightMenu />
        </Box>
    )

}

export default PriorAuthHeader;