import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { fetchFhirContext } from "../../api/api";

const MessagePopUp = (props) => {
  const { open, setOpen, data, resourceType } = props;
  
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelClick = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const prov_id = localStorage.getItem("pro_id");
      const req_body = {
        id: data.FhirId,
        provId: prov_id,
        resourceType: resourceType,
      };
      const response = await fetchFhirContext(req_body);
      if (response && response.url) {
        window.open(response.url, "_blank");
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching the response URL:", error);
    } finally {
     setIsLoading(false);
     setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      Width="1500px"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#000235",
          borderRadius: "10px",
          width: "40%",
        },
      }}
    >
      <DialogContent dividers sx={{ background: "rgb(61, 61, 166, 0.4)" }}>
        {/* <Grid
          container
          justifyContent="flex-end" 
          alignItems="flex-start"
        >
          <IconButton onClick={() => handleCancelClick()}>
            <HighlightOffOutlinedIcon sx={{ color: "white" }} />
          </IconButton>
        </Grid> */}
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          paddingTop="10px"
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "1.2rem",
              fontWeight: "500",
            }}
          >
            {data?.Message}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mt={2}
          sx={{ justifyContent: "flex-end", gap: "10px" }}
        >
          <Button variant="text" color="error" onClick={handleCancelClick}>
            <Typography>Cancel</Typography>
          </Button>
          {data?.Status_type === "Pending" &&
            data?.Message === "Additional information required" && (
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  "&.Mui-disabled": {
                    color: "white",
                    backgroundColor: "rgba(25, 118, 210, 0.5)",
                  },
                }}
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Proceed to CDex
                {isLoading && (
                  <CircularProgress
                    sx={{ ml: ".5rem", color: "white" }}
                    size={20}
                  />
                )}
              </Button>
            )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MessagePopUp;
