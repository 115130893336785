// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

/* .box .ellipse-wrapper {
  border: 0px none;
  height: 369px;
  width: 385px;
} */

.box .ellipse {
  background-color: #8d8ddacc;
  /* border-radius: 242.5px/234.5px; */
  filter: blur(100px);
  height: 269px;
  left: 0;
  position: fixed;
  top: 0;
  width: 285px;
}

.pointer {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;;;;GAMG;;AAEH;;;;GAIG;;AAEH;EACE,2BAA2B;EAC3B,oCAAoC;EACpC,mBAAmB;EACnB,aAAa;EACb,OAAO;EACP,eAAe;EACf,MAAM;EACN,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* .box {\n  background-color: transparent;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n} */\n\n/* .box .ellipse-wrapper {\n  border: 0px none;\n  height: 369px;\n  width: 385px;\n} */\n\n.box .ellipse {\n  background-color: #8d8ddacc;\n  /* border-radius: 242.5px/234.5px; */\n  filter: blur(100px);\n  height: 269px;\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 285px;\n}\n\n.pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
