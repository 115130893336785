import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const tableData = [
    {
        id: 'R04',
        patientInfo: ['Miller, Skye', 'F - 07 May 1992'],
        memberId: 'TH45632',
        mrn: '3564218',
        authType: 'Medical',
        submissionDate: '03 Aug 2022',
        lastUpdated: '03 Aug 2022',
        status: 'Draft',
        color: '#53ACFF',
        icon:  <SaveAsOutlinedIcon color="inherit"/>
    },
    {
        id: 'R05',
        patientInfo: ['Harper, Lee', 'F - 24 Sept 1983'],
        memberId: 'NH21365',
        mrn: '6532489',
        authType: 'Drug',
        submissionDate: '03 Aug 2022',
        lastUpdated: '03 Aug 2022',
        status: 'Question',
        color: '#F7FB30',
        icon:  <PriorityHighOutlinedIcon color="inherit"/> 
    },
    {
        id: 'R01',
        patientInfo: ['Johnson, Emma', 'F - 01 Jan 2000'],
        memberId: 'AX21455',
        mrn: '1564298',
        authType: 'Drug',
        submissionDate: '02 Aug 2022',
        lastUpdated: '02 Aug 2022',
        status: 'Info',
        color: '#FFBD14',
        icon:  <QuestionMarkOutlinedIcon color="inherit"/>
    },
    {
        id: 'R02',
        patientInfo: ['Anna, Baker', 'F - 16 Jul 1986'],
        memberId: 'BF36548',
        mrn: '3496571',
        authType: 'Drug',
        submissionDate: '05 Aug 2022',
        lastUpdated: '05 Aug 2022',
        status: 'Pending',
        color: '#F7FB30',
        icon:  <PriorityHighOutlinedIcon color="inherit"/>
    },
    {
        id: 'R03',
        patientInfo: ['Smith, Liam', 'M - 30 Mar 1995'],
        memberId: 'RG12546',
        mrn: '7546325',
        authType: 'Medical',
        submissionDate: '04 Aug 2022',
        lastUpdated: '04 Aug 2022',
        status: 'Approved',
        color: '#83FF57',
        icon:  <DoneAllOutlinedIcon color="inherit"/>
    },
    {
        id: 'R08',
        patientInfo: ['Adam, Lisa', 'M - 28 Feb 1987'],
        memberId: 'QD63259',
        mrn: '4569852',
        authType: 'Drug',
        submissionDate: '01 Aug 2022',
        lastUpdated: '01 Aug 2022',
        status: 'Denied',
        color: '#FF535D',
        icon:  <ClearOutlinedIcon color="inherit"/>
    }
];

export const newPatients = [
    {
        id: 'R07',
        firstName: 'Skyler',
        lastName: 'Jones',
        dob:  '25 Aug 1962',
        gender: 'Female',
        insuranceName: 'Beta Insurance',
        memberId: 'WE45623',
        mrn: '1564398',
        authType: 'Drug',
        submissionDate: '01 Aug 2022',
        lastUpdated: '01 Aug 2022',
        height: `5'10"`,
        weight: '143 lbs'
        /* status: 'Pending',
        color: '#F7FB30',
        icon:  <PriorityHighOutlinedIcon color="inherit"/> */
    },

    {
        id: 'R06',
        firstName: 'Jason',
        lastName: 'Mills',
        dob:  '31 Jan 1979',
        gender: 'Male',
        insuranceName: 'Alpha Insurance',
        memberId: 'TY63545',
        mrn: '7546329',
        authType: 'Medical',
        submissionDate: '02 Aug 2022',
        lastUpdated: '02 Aug 2022',
        height: `5'11"`,
        weight: '193 lbs'
        /* status: 'Approved',
        color: '#83FF57',
        icon:  <DoneAllOutlinedIcon color="inherit"/> */
    },
]

export const providers = [
    {
        providerName: 'Oliver Scott',
        npi: '7459861230',
        phone: '+1 201 2987481',
        fax: '+1 201 2987481',
        tin: '5642318946',
        address: '',
        department: 'General Surgery'
    }
]

export const facilities = [
    {
        name: 'Alpha Omega',
        npi: '4562315897',
        phone: '+1 469 5893672',
        fax: '+1 469 5893672',
        tin: '9564235674',
        address: '',
    }
]

export const steps = [
    {
      label: 'Patient Information'
    },
    {
      label: 'Provider Information',
    },
    // {
    //   label: 'Facility',
    // },
    {
        label: 'Organization Type',
      },
    {
      label: 'Service Type',
    },
  ];

export const radioButtonsOne = [
    'Inpatient',
    'Home',
    'Speech Therapy',
    'Home Health Care',
];

export const radioButtonsTwo = [
    'Outpatient',
    'Day Surgery',
    'Mental Health/Substance Abuse',
    'Provider Office',
];

export const radioButtonsThree = [
    
    'Physical Therapy',
    'Durable Medical Equipment',
    'Observation',
    'Occupational Therapy'
];

export const radioButtonsFour = [
    'Cardiac Rehab'
];

export const procedureInfo = {
    icd: 'L7M.1924',
    cpt: 'J0134',
    visits: '11 Aug 2022',
    frequency: '1/Week',
    plannedVisits: '11 Sep 2022',
    numOfVisits: 4
}

export const appointmentTypeOption = [
    {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "ROUTINE",
    display: "A follow up visit from a previous appointment",
    },
    {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "WALKIN",
    display: "A follow up visit from a previous appointment",
    },
    {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "CHECKUP",
    display: "A follow up visit from a previous appointment",
    },
    {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "FOLLOWUP",
    display: "A follow up visit from a previous appointment",
    },

    {
    system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    code: "EMERGENCY",
    display: "A follow up visit from a previous appointment",
    },
  ];

//export const procedureCodes = client.query('select * from public."Procedure_Codes"')
/*function retProcCodes(){
    const { Client } = require('pg')
  const client = new Client({
  user: 'safhiradmin',
  host: 'epapostgresserver.postgres.database.azure.com',
  database: 'epa',
  password: 'Epaserviceonyx@01',
  port: 5432,
})
client.connect(function(err) {
  if (err) throw err;
  console.log("Connected!");
});
    client.query('select * from public."Procedure_Codes" limit 2', (error, results) => {
        if (error) {
          throw error
        };
    procedureCodes = results.rows
    console.log(procedureCodes)})
    return procedureCodes;
}*/

//export const procedureCodes = retProcCodes();

// export const procedureCodes = [
//     {
//         code: '105585',
//         category: 'MedicationRequest',
//         description: 'Immuno Suppressive Drugs',
//         icd10: 'Z94.1',
//         dxDesc: 'Heart Transplant Status'
//     },
//     {
//         code: 'G0179',
//         category: 'ServiceRequest',
//         description: 'MD recertification HHA PT',
//         icd10: 'Z74.2',
//         dxDesc: 'Congestive Heart Failure'
//     },
//     {
//         code: 'G0180',
//         category: 'ServiceRequest',
//         description: 'MD certification HHA patient.',
//         icd10: 'Z74.3',
//         dxDesc: 'Congestive Heart Failure'
//     },
//     {
//         code: 'G0155',
//         category: 'ServiceRequest',
//         description: 'Hhcp-svs of csw,ea 15 min',
//         icd10: 'Z74.4',
//         dxDesc: 'Congestive Heart Failure'
//     },
//     {
//         code: 'E0250',
//         category: 'DeviceRequest',
//         description: 'Hospital Beds and Accessories',
//         icd10: '150.5',
//         dxDesc: 'Congestive Heart Failure' 
//     },
//     {
//         code: 'E0251',
//         category: 'DeviceRequest',
//         description: 'Hospital Beds and Accessories',
//         icd10: 'G89.6',
//         dxDesc: 'Chronic Pain' 
//     },
// 	{
//         code: 'E9999',
//         category: 'Appointment',
//         description: 'Nuclear Stress test',
//         icd10: 'I25.10',
//         dxDesc: 'Appointment Test'
//     },
// 	{
//         code: '105765',
//         category: 'Encounter',
//         description: 'Immuno Suppressive Drugs',
//         icd10: 'Z94.9',
//         dxDesc: 'Encounter Test'
//     },
// ]


export const class_data= ["AMB - ambulatory",  "EMER - emergency","FLD - field",  "HH - home health","IMP - inpatient encounter",  "ACUTE - inpatient acute","NONAC - inpatient non-acute",  "OBSENC - observation encounter","PRENC - pre-admission",  "SS - short stay","VR - virtual"]

export const status= ["planned","arrived" ,"triaged", "in-progress", "onleave", "finished", "cancelled" ]


export const card_data={
    "cards": [
        {
            "uuid": "15833361-3b50-44a9-8d8a-a5098c488cba",
            "summary": "Prior Authorization required",
            "detail": "Nebulizers: Prior Authorization required, follow the attached link for information.",
            "indicator": "info",
            "source": {
                "label": "Da Vinci CRD Reference Implementation",
                "url": null,
                "icon": null,
                "topic": {
                    "code": "prior-auth",
                    "system": "http://hl7.org/fhir/us/davinci-crd/CodeSystem/cardType",
                    "display": "Prior Authorization"
                }
            },
            "suggestions": [
                {
                    "label": "Save Update To EHR",
                    "uuid": "414b5d42-70df-4e7f-b1a4-a1ebb7cb1d84",
                    "actions": [
                        {
                            "type": "update",
                            "description": "Update original ServiceRequest to add note",
                            "resource": {
                                "insurance": [
                                    {
                                        "reference": "Coverage/2789766"
                                    }
                                ],
                                "extension": [
                                    {
                                        "url": "http://hl7.org/fhir/us/davinci-crd/StructureDefinition/ext-coverage-information",
                                        "extension": [
                                            {
                                                "url": "pa-needed",
                                                "valueCode": "auth-needed"
                                            },
                                            {
                                                "url": "covered",
                                                "valueCode": "covered"
                                            },
                                            {
                                                "url": "coverage",
                                                "valueReference": {
                                                    "reference": "Coverage/2789766"
                                                }
                                            },
                                            {
                                                "url": "date",
                                                "valueDate": "2024-04-22"
                                            },
                                            {
                                                "url": "identifier",
                                                "valueString": "b968c269-2442-433a-af44-e2ec77fcf6ad"
                                            }
                                        ]
                                    }
                                ],
                                "code": {
                                    "coding": [
                                        {
                                            "system": "https://bluebutton.cms.gov/resources/codesystem/hcpcs",
                                            "code": "E9991",
                                            "display": "Nebulizers"
                                        },
                                        {
                                            "system": "http://hl7.org/fhir/sid/icd-10",
                                            "code": "I25.10",
                                            "display": "Nebulizers"
                                        }
                                    ]
                                },
                                "authoredOn": "2024-04-22T14:54:52.204Z",
                                "performer": [
                                    {
                                        "reference": "Practitioner/MMI-Physician-001"
                                    }
                                ],
                                "subject": {
                                    "reference": "Patient/PAT-001"
                                },
                                "encounter": {
                                    "reference": "Encounter/2792408"
                                },
                                "intent": "order",
                                "meta": {
                                    "versionId": "1",
                                    "lastUpdated": "2024-04-22T14:54:53.000+00:00",
                                    "source": "#sv0spqLCoU3zspPF"
                                },
                                "occurrenceDateTime": "2024-04-22T14:54:52.204Z",
                                "id": "2792409",
                                "resourceType": "ServiceRequest",
                                "status": "active"
                            },
                            "resourceId": "ServiceRequest/2792409"
                        }
                    ],
                    "isRecommended": true
                }
            ],
            "selectionBehavior": null,
            "links": [
                {
                    "label": "Documentation Requirements",
                    "url": "https://www.cms.gov/Research-Statistics-Data-and-Systems/Computer-Data-and-Systems/Electronic-Clinical-Templates/Downloads/Respiratory-Assist-Device-Order-Template-Draft-20180412-R10b.pdf",
                    "type": "absolute",
                    "appContext": null
                },
                {
                    "label": "Order Form",
                    "url": "http://localhost:3005/launch?launch=BTMr8O&iss=https://gw.interop.community/Jackson4Saffron/data&appcontext=questionnaire%3Dhttp%3A%2F%2Flocalhost%3A8090%2Ffhir%2Fr4%2FQuestionnaire%2FNebulizersOrder%26order%3D%7B%5C%22resourceType%5C%22%3A%5C%22ServiceRequest%5C%22%2C%5C%22id%5C%22%3A%5C%222792409%5C%22%2C%5C%22meta%5C%22%3A%7B%5C%22versionId%5C%22%3A%5C%221%5C%22%2C%5C%22lastUpdated%5C%22%3A%5C%222024-04-22T14%3A54%3A53.000%2B00%3A00%5C%22%2C%5C%22source%5C%22%3A%5C%22%23sv0spqLCoU3zspPF%5C%22%7D%2C%5C%22status%5C%22%3A%5C%22draft%5C%22%2C%5C%22intent%5C%22%3A%5C%22order%5C%22%2C%5C%22code%5C%22%3A%7B%5C%22coding%5C%22%3A%5B%7B%5C%22system%5C%22%3A%5C%22https%3A%5C%2F%5C%2Fbluebutton.cms.gov%5C%2Fresources%5C%2Fcodesystem%5C%2Fhcpcs%5C%22%2C%5C%22code%5C%22%3A%5C%22E9991%5C%22%2C%5C%22display%5C%22%3A%5C%22Nebulizers%5C%22%7D%2C%7B%5C%22system%5C%22%3A%5C%22http%3A%5C%2F%5C%2Fhl7.org%5C%2Ffhir%5C%2Fsid%5C%2Ficd-10%5C%22%2C%5C%22code%5C%22%3A%5C%22I25.10%5C%22%2C%5C%22display%5C%22%3A%5C%22Nebulizers%5C%22%7D%5D%7D%2C%5C%22subject%5C%22%3A%7B%5C%22reference%5C%22%3A%5C%22Patient%5C%2FPAT-001%5C%22%7D%2C%5C%22encounter%5C%22%3A%7B%5C%22reference%5C%22%3A%5C%22Encounter%5C%2F2792408%5C%22%7D%2C%5C%22occurrenceDateTime%5C%22%3A%5C%222024-04-22T14%3A54%3A52.204Z%5C%22%2C%5C%22authoredOn%5C%22%3A%5C%222024-04-22T14%3A54%3A52.204Z%5C%22%2C%5C%22performer%5C%22%3A%5B%7B%5C%22reference%5C%22%3A%5C%22Practitioner%5C%2FMMI-Physician-001%5C%22%7D%5D%2C%5C%22insurance%5C%22%3A%5B%7B%5C%22reference%5C%22%3A%5C%22Coverage%5C%2F2789766%5C%22%7D%5D%7D%26coverage%3DCoverage%2F2789766",
                    "type": "smart",
                    "appContext": "questionnaire%3Dhttp%3A%2F%2Flocalhost%3A8090%2Ffhir%2Fr4%2FQuestionnaire%2FNebulizersOrder%26order%3D%7B%5C%22resourceType%5C%22%3A%5C%22ServiceRequest%5C%22%2C%5C%22id%5C%22%3A%5C%222792409%5C%22%2C%5C%22meta%5C%22%3A%7B%5C%22versionId%5C%22%3A%5C%221%5C%22%2C%5C%22lastUpdated%5C%22%3A%5C%222024-04-22T14%3A54%3A53.000%2B00%3A00%5C%22%2C%5C%22source%5C%22%3A%5C%22%23sv0spqLCoU3zspPF%5C%22%7D%2C%5C%22status%5C%22%3A%5C%22draft%5C%22%2C%5C%22intent%5C%22%3A%5C%22order%5C%22%2C%5C%22code%5C%22%3A%7B%5C%22coding%5C%22%3A%5B%7B%5C%22system%5C%22%3A%5C%22https%3A%5C%2F%5C%2Fbluebutton.cms.gov%5C%2Fresources%5C%2Fcodesystem%5C%2Fhcpcs%5C%22%2C%5C%22code%5C%22%3A%5C%22E9991%5C%22%2C%5C%22display%5C%22%3A%5C%22Nebulizers%5C%22%7D%2C%7B%5C%22system%5C%22%3A%5C%22http%3A%5C%2F%5C%2Fhl7.org%5C%2Ffhir%5C%2Fsid%5C%2Ficd-10%5C%22%2C%5C%22code%5C%22%3A%5C%22I25.10%5C%22%2C%5C%22display%5C%22%3A%5C%22Nebulizers%5C%22%7D%5D%7D%2C%5C%22subject%5C%22%3A%7B%5C%22reference%5C%22%3A%5C%22Patient%5C%2FPAT-001%5C%22%7D%2C%5C%22encounter%5C%22%3A%7B%5C%22reference%5C%22%3A%5C%22Encounter%5C%2F2792408%5C%22%7D%2C%5C%22occurrenceDateTime%5C%22%3A%5C%222024-04-22T14%3A54%3A52.204Z%5C%22%2C%5C%22authoredOn%5C%22%3A%5C%222024-04-22T14%3A54%3A52.204Z%5C%22%2C%5C%22performer%5C%22%3A%5B%7B%5C%22reference%5C%22%3A%5C%22Practitioner%5C%2FMMI-Physician-001%5C%22%7D%5D%2C%5C%22insurance%5C%22%3A%5B%7B%5C%22reference%5C%22%3A%5C%22Coverage%5C%2F2789766%5C%22%7D%5D%7D%26coverage%3DCoverage%2F2789766"
                }
            ],
            "extension": {
                "davinci-associated-resource": [
                    "https://gw.interop.community/Jackson4Saffron/data/ServiceRequest/2792409/_history/1"
                ]
            }
        }
    ],
    "systemActions": [
        {
            "type": "update",
            "description": "Update original ServiceRequest to add note",
            "resource": {
                "insurance": [
                    {
                        "reference": "Coverage/2789766"
                    }
                ],
                "extension": [
                    {
                        "url": "http://hl7.org/fhir/us/davinci-crd/StructureDefinition/ext-coverage-information",
                        "extension": [
                            {
                                "url": "pa-needed",
                                "valueCode": "auth-needed"
                            },
                            {
                                "url": "covered",
                                "valueCode": "covered"
                            },
                            {
                                "url": "coverage",
                                "valueReference": {
                                    "reference": "Coverage/2789766"
                                }
                            },
                            {
                                "url": "date",
                                "valueDate": "2024-04-22"
                            },
                            {
                                "url": "questionnaire",
                                "valueCanonical": "http://localhost:8090/fhir/r4/Questionnaire/NebulizersOrder"
                            },
                            {
                                "url": "detail",
                                "extension": [
                                    {
                                        "url": "code",
                                        "valueCodeableConcept": {
                                            "text": "instruction"
                                        }
                                    },
                                    {
                                        "url": "value",
                                        "valueString": "Please Visit : https://www.cms.gov/Research-Statistics-Data-and-Systems/Computer-Data-and-Systems/Electronic-Clinical-Templates/Downloads/Respiratory-Assist-Device-Order-Template-Draft-20180412-R10b.pdf"
                                    }
                                ]
                            },
                            {
                                "url": "identifier",
                                "valueString": "acde3c3f-76cf-457c-a74a-725459d77b70"
                            }
                        ]
                    }
                ],
                "code": {
                    "coding": [
                        {
                            "system": "https://bluebutton.cms.gov/resources/codesystem/hcpcs",
                            "code": "E9991",
                            "display": "Nebulizers"
                        },
                        {
                            "system": "http://hl7.org/fhir/sid/icd-10",
                            "code": "I25.10",
                            "display": "Nebulizers"
                        }
                    ]
                },
                "authoredOn": "2024-04-22T14:54:52.204Z",
                "performer": [
                    {
                        "reference": "Practitioner/MMI-Physician-001"
                    }
                ],
                "subject": {
                    "reference": "Patient/PAT-001"
                },
                "encounter": {
                    "reference": "Encounter/2792408"
                },
                "intent": "order",
                "meta": {
                    "versionId": "1",
                    "lastUpdated": "2024-04-22T14:54:53.000+00:00",
                    "source": "#sv0spqLCoU3zspPF"
                },
                "occurrenceDateTime": "2024-04-22T14:54:52.204Z",
                "id": "2792409",
                "resourceType": "ServiceRequest",
                "status": "active"
            },
            "resourceId": "ServiceRequest/2792409"
        }
    ]
}

export const ACCESS_TOKEN_STORAGE_KEY = `oauth_access_token`;
export const REFRESH_TOKEN_STORAGE_KEY = `oauth_refresh_token`;
export const ID_TOKEN_STORAGE_KEY = `oauth_id_token`;