import React, { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardFilters from "./DashboardFilters";
import { Box } from "@mui/material";
import DashboardTable from "./DashboardTable";
import NewAuthDialog from "./NewAuthDialog";
import { fetchPatients } from "../../api/api";
import PatientViewDialog from "./PatientViewDailog";
import PatientSelectDialog from "./PatientSelectDailog";
import EnhancedTable from "../EnhancedTable";

const headers = [
  { id: "memberId", label: "Member ID" },
  { id: "patientName", label: "Patient Name" },
  // { id: "mrn", label: "MRN" },
  { id: "dateOfBirth", label: "Date Of Birth" },
  { id: "gender", label: "Gender" },
  { id: "active", label: "Status" },
  { id: "address", label: "Address" },
  // { id: "lastUpdated", label: "Last Updated" },
];

const DashboardContent = () => {
  const [open, setOpen] = useState(false);
  const [openPatientView, setOpenPatientView] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tabData, setTabData] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("patientname");
  const [filterValues, setFilterValues] = useState({
    // firstName: "",
    // lastName: "",
    patientname:"",
    memberId:"",
    gender: "",
    dateofbirth: "",
  });

  const [filterOpen, setFilterOpen] = useState(false);

  const user = localStorage.getItem("user");

  const getPatients = async (queryParams = "") => {
    setLoading(true);
    const data = await fetchPatients(user, queryParams);
    setPatientList(data);
    setTabData(data?.data);
    setLoading(false);
  };

  const handleOpen = () => setFilterOpen(true);
  const handleClose = () => setFilterOpen(false);

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleFilterValueChange = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleClearFilter = (e) => {
    e.stopPropagation();
    handleClose();
    if(filterValues.patientname || filterValues.memberId || filterValues.gender || filterValues.dateofbirth){
    setFilterValues({
      // firstName: "",
      // lastName: "",
      patientname: "",
      memberId: "",
      gender: "",
      dateofbirth: "",
    });
    getPatients();
   }
    setFilterType("patientname");
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    let queryParams = "";
    // if (filterValues.firstName) {
    //   queryParams += `&firstName=${filterValues.firstName}`;
    // }
    // if (filterValues.lastName) {
    //   queryParams += `&lastName=${filterValues.lastName}`;
    // }
    if(filterValues.patientname){
      queryParams += `&patientname=${filterValues.patientname}`
    }
    if(filterValues.memberId) {
      queryParams += `&memberId=${filterValues.memberId}`
    }
    if (filterValues.gender) {
      queryParams += `&gender=${filterValues.gender.toLowerCase()}`;
    }
    if (filterValues.dateofbirth) {
      queryParams += `&dateOfBirth=${filterValues.dateofbirth}`;
    }

    if (queryParams) {
      queryParams = "?" + queryParams.slice(1);
      getPatients(queryParams);
    }
  };

  const handlePagePagination = (page, rowsPerPage) => {
    let queryParams = "";
    // if (filterValues.firstName) {
    //   queryParams += `&firstName=${filterValues.firstName}`;
    // }
    // if (filterValues.lastName) {
    //   queryParams += `&lastName=${filterValues.lastName}`;
    // }
    if(filterValues.patientname){
      queryParams += `&patientname=${filterValues.patientname}`
    }
    if(filterValues.memberId) {
      queryParams += `&memberId=${filterValues.memberId}`
    }
    if (filterValues.gender) {
      queryParams += `&gender=${filterValues.gender.toLowerCase()}`;
    }
    if (filterValues.dateofbirth) {
      queryParams += `&dateOfBirth=${filterValues.dateofbirth}`;
    }
    if (page) {
      queryParams += `&page=${page}`;
    }
    if (rowsPerPage) {
      queryParams += `&limit=${rowsPerPage}`;
    }
    if (queryParams) {
      queryParams = "?" + queryParams.slice(1);
      getPatients(queryParams);
    }
  };


  useEffect(() => {
    getPatients();
  }, []);

  return (
    <>
      {open && <PatientSelectDialog open={open} setOpen={setOpen} selectedRowData={selectedRowData} />}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <DashboardHeader />
        <DashboardFilters
          setOpen={setOpen}
          setSearchParam={setSearchParam}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleFilterTypeChange={handleFilterTypeChange}
          filterType={filterType}
          handleFilterValueChange={handleFilterValueChange}
          filterValues={filterValues}
          handleApplyFilter={handleApplyFilter}
          handleClearFilter={handleClearFilter}
          filterOpen={filterOpen}
        />
        <EnhancedTable
          headers={headers}
          rows={tabData ? tabData : []}
          page={patientList?.currentPage}
          totalPages={patientList?.totalPages}
          onRowClick={(row) => {
            setOpen(true);
            setSelectedRowData(row);
          }}
          onChange={handlePagePagination}
          loading={loading}
        />
      </Box>
      
      {/* {openPatientView && (
        <PatientViewDialog
          openPatientView={openPatientView}
          setOpenPatientView={setOpenPatientView}
          selectedRowData={selectedRowData}
        />
      )} */}
    </>
  );
};

export default DashboardContent;
