import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  IconButton,
  Card,
  Button,
  CardContent,
} from "@mui/material";

import React, { useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { AddOutlined } from "@mui/icons-material";
import NewAuthTypeContent from "./NewAuthTypeContent";
import PatientViewDialog from "./PatientViewDailog";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DrugIcon } from "../../assets/drug_logo.svg";
import { ReactComponent as SyringeIcon } from "../../assets/syringe_logo.svg";
import { ReactComponent as GroupSmallIcon } from "../../assets/Group_small.svg";
import { ReactComponent as MedicalIcon } from "../../assets/Medical_logo.svg";
import { ReactComponent as StethescopeIcon } from "../../assets/stethescope_logo.svg";

const PatientSelectDialog = (props) => {
  const {
    open,
    setOpen,
    selectedRowData,
  } = props;


  const [showAuthType, setShowAuthType] = useState(false);
  const [showPatientView, setShowPatientView] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handlePriorAuthType = authType => {
    localStorage.setItem("authType", authType);
    localStorage.setItem("selectedPatient", selectedRowData.memberId);
    navigate("/priorAuth");
  }
  const handleEncounter = () => {
    localStorage.setItem("selectedPatient", selectedRowData.memberId);
    navigate("/encounter");
  }


  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth={true}
      maxWidth={showPatientView ? "lg" : "md"}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#000235",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "white",
          backgroundColor: "rgb(61, 61, 166,0.4)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      > 
       {showAuthType && (
          <IconButton onClick={() => setShowAuthType(false)}>
            <KeyboardBackspaceOutlinedIcon sx={{color: 'white'}} />
          </IconButton>
        )}
        {showPatientView && (
          <IconButton onClick={() => setShowPatientView(false)}>
            <KeyboardBackspaceOutlinedIcon sx={{color: 'white'}} />
          </IconButton>
        )}
        <Typography
          sx={{
            color: "white",
            flexGrow: 1,
            paddingLeft: "1rem",
          }}
        >
         {showAuthType ?  "Add New Authorization" : showPatientView ? "Patient Details" : "Prior Auth Service"}
          {/* {title} */}
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <HighlightOffOutlinedIcon sx={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "1rem !important" }}>
        <Box
          sx={{
            padding: "1rem",
            // border: '1px solid white',
            backgroundColor: "unset",
          }}
        >
          {showPatientView ? 
           <PatientViewDialog selectedRowData={selectedRowData} />  :
          <Paper
            sx={{
              padding: "0.625rem 1.5rem",
              display: "flex",
              flexDirection: "column",
              border: "2px solid white",
              boxShadow: "none",
              backgroundColor: "unset",
              borderRadius: "10px",
            }}
          > 
           
            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Last Name: {selectedRowData.lastName}
                </Typography>
                <Typography variant="h6" sx={{ color: "white" }}>
                  First Name: {selectedRowData.firstName}
                </Typography>
              </Box>
              <Box
                // sx={{ display: "flex", justifyContent: "flex-start" }}
                sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
              >
                <Typography variant="h6" sx={{ color: "white" }}>
                  MRN: {selectedRowData.mrn}
                </Typography>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Member ID: {selectedRowData.memberId}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                // mt: 2,
                p: 3,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body1" sx={{ color: "white" }}>
                 {showAuthType ? "Select the type of prior authorization" : ""}
                </Typography>
              </Box>
              {!showAuthType ? 
              <Box>
               <Card
               component={Button}
               variant="outlined"
               sx={{
                 display: "flex",
                 p: "0.7em 1.5em 0.7em 1.5em",
                 alignItems: "center",
                 justifyContent: "center",
                 background: "inherit",
                 border: "2px solid #ABD9D9",
                 borderRadius: 20,
                 width: "100%",
                 mb:"10px"
               }}
               onClick={() => handlePriorAuthType("Order")}
             >
               <AddOutlined sx={{ color: "white", mr: "3px" }} />
               <Typography variant="h6" sx={{ fontSize: "130%" }}>
                 Add New Authorization
               </Typography>
             </Card>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px"
                  // p:4,
                }}
              >
                <Card
                  component={Button}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    p: "0.7em 1.5em 0.7em 1.5em",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    border: "2px solid #ABD9D9",
                    borderRadius: 20,
                  }}
                  onClick={() => setShowPatientView(true)}
                >
                   <AddOutlined sx={{ color: "white" }} /> 
                  <Typography variant="h6" sx={{ fontSize: "130%" }}>
                  View Patient Details
                  <CardContent sx={{ display: "flex" }}>
                  <DrugIcon sx={{fontSize: "16px"}} />
                  <SyringeIcon sx={{fontSize: "16px"}} />
                  </CardContent>
                  </Typography>
                </Card>
                <Card
                  component={Button}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    p: "0.7em 1.5em 0.7em 1.5em",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    border: "2px solid #ABD9D9",
                    borderRadius: 20,
                    // ml: 3,
                  }}
                  onClick={() => handleEncounter()}
                >
                  <AddOutlined sx={{ color: "white" }} 
                  <Typography variant="h6" sx={{ fontSize: "130%" }}>
                    View Patient Encounter
                  </Typography>
                </Card>
              </Box> */}
               <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2,  }}>
        <Button
          variant="outlined"
          sx={{
            color: '#fff',
            border: "2px solid #ABD9D9",
            flex: 1,
            marginRight: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 20,
            "&:hover": {
              border: "2px solid #ABD9D9"
            }
          }}
          onClick={() => setShowPatientView(true)}
        >
          View Patient Details
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }}>
          <DrugIcon />
          <SyringeIcon />
          </Box>
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: '#fff',
            border: "2px solid #ABD9D9",
            flex: 1,
            marginLeft: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 20,
            "&:hover": {
              border: "2px solid #ABD9D9"
            }

          }}
          onClick={() => handleEncounter()}
        >
          View Patient Encounter
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1, pb: "5px" }}>
          <GroupSmallIcon />
            <MedicalIcon />
            <StethescopeIcon />
          </Box>
        </Button>
      </Box>
              </Box>
               : 
              <NewAuthTypeContent patient={selectedRowData} /> }
            </Box>
            
          </Paper> }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PatientSelectDialog;
