import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  remaining,
  handleStay,
  handleLogout,
  timeout
}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const inactiveMinute = (timeout / 1000) / 60;

  return (
  
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'xs'}
        // onClose={handleClose}
        // onBackdropClick={}
        sx={{
          ".css-pclwc1-MuiTypography-root-MuiDialogTitle-root": {
            color: "white",
          },
          ".css-twia2z-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "#494A8A"
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Your are inactive for more than {inactiveMinute || 10} minutes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{color: 'white', lineHeight: 2}}>
            Are you still here? <br />
            <span>Logging out in {remaining} seconds</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{
            background: "inherit",
            border: "1px solid white",
            color: "white",
            borderRadius:10
          }} onClick={() => handleStay()}>Stay</Button>
          <Button sx={{
            background: "inherit",
            // border: "1px solid white",
            color: "white",
            borderRadius:10
          }}  onClick={() => handleLogout()}>LogOut</Button>
        </DialogActions>
      </Dialog>
  );
}
