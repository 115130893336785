export const users = [
    {
        username: 'oliver.scott',
        password: 'password123',
        role: 'provider'
    },
    {
        username: 'michael.scott',
        password: 'theoffice123',
        role: 'payer'
    }
]