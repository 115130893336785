import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { steps } from "../../util/constants";
import StepperContent from "./StepperContent";
import { fetchOrganizationCodes, fetchProcedureCodes } from "../../api/api";

const PriorAuthStepper = (props) => {
  const {
    showProcedures,
    value,
    handleChange,
    patient,
    provider,
    selectedProc,
    setSelectedProc,
    selectedOrg,
    setSelectedOrg,
    showOrderSelect,
    handleCRDrequest,
    crdResponse,
    appointmentDate,
    setAppointmentDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    appointmentType,
    setAppointmentType,
    setSelectedHook,
    setShowForm,
    setShowProcedures,
    checked,
    handleCheckBoxChange,
  } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [procedureCodes, setprocCodes] = useState([]);
  const [organizationCodes, setOrganizationCodes] = useState([]);

  const fetchProcedureList = async () => {
    const data = await fetchProcedureCodes();
    setprocCodes(data);
  };

  const fetchOrganizationCodesList = async () => {
    const data = await fetchOrganizationCodes();
    setOrganizationCodes(data);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    fetchProcedureList();
    fetchOrganizationCodesList();
  }, []);

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                ".css-1rw7nzj-MuiCollapse-root-MuiStepContent-transition": {
                  width: "100%",
                },
              }}
            >
              <StepperContent
                step={activeStep}
                value={value}
                handleChange={handleChange}
                showProcedures={showProcedures}
                patient={patient}
                provider={provider}
                selectedProc={selectedProc}
                setSelectedProc={setSelectedProc}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                organizationCodes={organizationCodes}
                showOrderSelect={showOrderSelect}
                handleCRDrequest={handleCRDrequest}
                crdResponse={crdResponse}
                appointmentDate={appointmentDate}
                setAppointmentDate={setAppointmentDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                appointmentType={appointmentType}
                setAppointmentType={setAppointmentType}
                setSelectedHook={setSelectedHook}
                setShowForm={setShowForm}
                setShowProcedures={setShowProcedures}
                checked={checked}
                handleCheckBoxChange={handleCheckBoxChange}
                procedureCodes={procedureCodes}
              />
              <Box sx={{ mb: 2 }}>
                <div>
                  {activeStep !== 3 && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        mt: 1,
                        mr: 1,
                        "&:disabled": {
                          pointerEvents: "all !important",
                          cursor: "not-allowed",
                          backgroundColor: "#dddddd",
                        },
                      }}
                      disabled={
                        Object.keys(patient).length === 0 ||
                        patient?.message ||
                        (activeStep === 2 && selectedOrg.orgName === "")
                      }
                    >
                      Continue
                    </Button>
                  )}
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3, backgroundColor: "inherit" }}>
          {/* <Typography sx={{color: 'white'}}>All steps completed - you&apos;re finished</Typography> */}
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default PriorAuthStepper;
