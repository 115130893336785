import React, { useState } from "react";
import Header from "../components/header/Header";
import LoginContent from "../components/login/LoginContent";
import { Box } from "@mui/material";
import Footer from "../components/Footer";

const Login = () => {

    const [activeButton, setActiveButton] = useState("");

  return (
    <Header
      children={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <LoginContent activeButton={activeButton} setActiveButton={setActiveButton} />
          <Footer />
        </Box>
      }
    />
  );
};

export default Login;
