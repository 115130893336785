import {
  Box,
  Card,
  Grid,
  Typography,
  Alert,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { priorAuthStyles } from "./PriorAuthStyles";

const Organization = (props) => {
  const { selectedOrg, setSelectedOrg, organizationCodes } = props;
  // const [searchPractRoleId, setSearchPractRoleId] = useState("");
  // const [searchOrgId, setSearchOrgId] = useState("");
  const [searchOrgName, setSearchOrgName] = useState("");

  // const handleInputChange = (event, value, reason) => {
  //   setSearchPractRoleId(value);
  // };
  // const handleOrgIdChange = (event, value, reason) => {
  //   setSearchOrgId(value);
  // };

  const handleOrgNameChange = (event, value, reason) => {
    setSearchOrgName(value);
  };

  return (
    <form >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 10,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <span style={{ width: 400, borderBottom: "1px solid #ABDDDD" }} />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Organization Information
          </Typography>
          <span style={{ width: 400, borderBottom: "1px solid #ABDDDD" }} />
        </Box>
        <Card
          sx={{
            backgroundColor: "#d9d9d91a",
            borderRadius: 10,
            p: 5,
          }}
        >
          <Grid container spacing={4}>
            <Grid item container xs={15} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  Organization Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={organizationCodes ?? []}
                  value={selectedOrg}
                  onChange={(event, newValue) => {
                    setSelectedOrg(
                      newValue ?? {
                        practRoleId: "",
                        orgId: "",
                        orgName: "",
                      }
                    );
                  }}
                  inputMode="search"
                  loadingText="Loading orgName"
                  inputValue={searchOrgName}
                  onInputChange={handleOrgNameChange}
                  getOptionLabel={(option) => option.orgName ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.orgName === value.orgName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      color={"secondary"}
                      focused
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container xs={9} md={6}></Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  Practitioner Role Id
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    ...priorAuthStyles.inputColor,
                    borderBottom: "solid 1px white",
                  }}
                >
                  &nbsp; {selectedOrg.practRoleId || " "}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  Organization Id
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    ...priorAuthStyles.inputColor,
                    borderBottom: "solid 1px white",
                  }}
                >
                  &nbsp; {selectedOrg.orgId || " "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </form>
  );
};

export default Organization;
