import {
  Box,
  Card,
  Grid,
  Typography,
  Alert,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { appointmentTypeOption, procedureInfo } from "../../util/constants";
import { priorAuthStyles } from "./PriorAuthStyles";

const Procedures = (props) => {
  const {
    patient,
    provider,
    selectedProc,
    setSelectedProc,
    showOrderSelect,
    value,
    handleCRDrequest,
    crdResponse,
    appointmentDate,
    setAppointmentDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    appointmentType,
    setAppointmentType,
    procedureCodes,
    setSelectedHook,
    setShowForm,
    setShowProcedures,
  } = props;
  const [searchICDCodes, setSearchIDCCode] = useState("");
  const [searchCPTCodes, setSearchCPTCode] = useState("");
  const [searchDescription, setSearchDescription] = useState("");
  const [searchdxDesc, setSearchdxDesc] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filterData = procedureCodes.filter((procedure) =>
      procedure.category.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filterData);
  }, [value]);

  console.log(filteredData);

  const handleInputChange = (event, value, reason) => {
    setSearchIDCCode(value);
  };
  const handleCPTInputChange = (event, value, reason) => {
    setSearchCPTCode(value);
  };

  const handleDescriptionChange = (event, value, reason) => {
    setSearchDescription(value);
  };
  const handleDxDescChange = (event, value, reason) => {
    setSearchdxDesc(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowProcedures(false);
    setShowForm(true);
    setSelectedHook(
      value === "Appointment" ? "appointment-book" : "order-sign"
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 10,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <span style={{ width: 400, borderBottom: "1px solid #ABDDDD" }} />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Procedure Information
          </Typography>
          <span style={{ width: 400, borderBottom: "1px solid #ABDDDD" }} />
        </Box>
        {/* <Typography
          variant="body1"
          sx={{ ...priorAuthStyles.flexEndDiv, ...priorAuthStyles.inputColor }}
        >
          Date of Service: 09 Aug 2022
        </Typography> */}
        <Card
          sx={{
            backgroundColor: "#d9d9d91a",
            borderRadius: 10,
            p: 5,
          }}
        >
          <Grid container spacing={4}>
            {/* <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  ICD9/ICD10
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={filteredData ?? []}
                  value={selectedProc}
                  onChange={(event, newValue) => {
                    setSelectedProc(
                      newValue ?? {
                        icd10: "",
                        code: "",
                        description: "",
                        dxDesc: "",
                        category: "",
                      }
                    );
                    if (newValue !== null) {
                      handleCRDrequest(
                        value,
                        patient,
                        provider,
                        newValue,
                        "order-select"
                      );
                    }
                  }}
                  inputMode="search"
                  loadingText="Loading ICD Codes"
                  inputValue={searchICDCodes}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option) => option.icd10 ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.icd10 === value.icd10
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      color={"secondary"}
                      focused
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid> */}
            {/* <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  Dx Description
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={filteredData ?? []}
                  value={selectedProc}
                  onChange={(event, newValue) => {
                    setSelectedProc(
                      newValue ?? {
                        icd10: "",
                        code: "",
                        description: "",
                        dxDesc: "",
                        category: "",
                      }
                    );
                    if (newValue !== null) {
                      handleCRDrequest(
                        value,
                        patient,
                        provider,
                        newValue,
                        "order-select"
                      );
                    }
                  }}
                  inputMode="search"
                  loadingText="Loading dxDesc"
                  inputValue={searchdxDesc}
                  onInputChange={handleDxDescChange}
                  getOptionLabel={(option) => option.dxDesc ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.dxDesc === value.dxDesc
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      color={"secondary"}
                      focused
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid> */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  {`${
                    value == "ServiceRequest"
                      ? "Service code"
                      : value == "MedicationRequest"
                      ? "Medication code"
                      : value == "DeviceRequest"
                      ? "Device code"
                      : "Service Type"
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={filteredData ?? []}
                  // loading={isProcedureTypesLoading}
                  value={selectedProc}
                  onChange={(event, newValue) => {
                    setSelectedProc(
                      newValue ?? {
                        icd10: "",
                        code: "",
                        description: "",
                        dxDesc: "",
                        category: "",
                      }
                    );
                    // if (newValue !== null) {
                    //   handleCRDrequest(
                    //     value,
                    //     patient,
                    //     provider,
                    //     newValue,
                    //     "order-select"
                    //   );
                    // }
                  }}
                  inputMode="search"
                  loadingText="Loading CPT Codes"
                  inputValue={searchCPTCodes}
                  onInputChange={handleCPTInputChange}
                  getOptionLabel={(option) => option.code ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      color={"secondary"}
                      focused
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  Description
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6" sx={{ ...priorAuthStyles.inputColor }}>
                  :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={filteredData ?? []}
                  // loading={isProcedureTypesLoading}
                  value={selectedProc}
                  onChange={(event, newValue) => {
                    setSelectedProc(
                      newValue ?? {
                        icd10: "",
                        code: "",
                        description: "",
                        dxDesc: "",
                        category: "",
                      }
                    );
                    // if (newValue !== null) {
                    //   handleCRDrequest(
                    //     value,
                    //     patient,
                    //     provider,
                    //     newValue,
                    //     "order-select"
                    //   );
                    // }
                  }}
                  inputMode="search"
                  loadingText="Loading description"
                  inputValue={searchDescription}
                  onInputChange={handleDescriptionChange}
                  getOptionLabel={(option) => option.description ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      color={"secondary"}
                      focused
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {value !== "MedicationRequest" && value !== "Appointment" && (
              <Grid item container xs={12} md={6}>
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Date of Service
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    required
                    variant="standard"
                    focused
                    color={"secondary"}
                    value={appointmentDate}
                    onChange={(e) => {
                      const value = e.target.value;
                      setAppointmentDate(value);
                    }}
                    id="appointment-date"
                    fullWidth
                    type="date"
                    InputProps={{
                      style: { ...priorAuthStyles.inputColor },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {value == "Appointment" && (
              <>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      Appointment Type
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={appointmentTypeOption ?? []}
                      value={appointmentType}
                      onChange={(event, newValue) => {
                        setAppointmentType(newValue ?? "");
                      }}
                      inputMode="search"
                      loadingText="Loading description"
                      getOptionLabel={(option) => option.code ?? ""}
                      isOptionEqualToValue={(option, value) =>
                        option.code === value.code
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required
                          color={"secondary"}
                          focused
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}></Grid>
              </>
            )}

            {value == "Appointment" && (
              <>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Request Appointment between
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      required
                      variant="standard"
                      focused
                      color={"secondary"}
                      value={startDate}
                      onChange={(e) => {
                        const value = e.target.value;
                        setStartDate(value);
                      }}
                      id="start-date"
                      fullWidth
                      type="date"
                      InputProps={{
                        style: { ...priorAuthStyles.inputColor },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      required
                      variant="standard"
                      focused
                      color={"secondary"}
                      value={endDate}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEndDate(value);
                      }}
                      id="start-date"
                      fullWidth
                      type="date"
                      InputProps={{
                        style: { ...priorAuthStyles.inputColor },
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {/* ) : ( 
              <>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Planned Visits From
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        ...priorAuthStyles.inputColor,
                        borderBottom: "1px solid white",
                      }}
                    >
                      {procedureInfo.visits}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Planned Visits To
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        ...priorAuthStyles.inputColor,
                        borderBottom: "1px solid white",
                      }}
                    >
                      {procedureInfo.plannedVisits}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      Frequency
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h6"
                      sx={{ ...priorAuthStyles.inputColor }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        ...priorAuthStyles.inputColor,
                        borderBottom: "1px solid white",
                      }}
                    >
                      {procedureInfo.frequency}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Number Of Visits
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        borderBottom: "1px solid white",
                      }}
                    >
                      {procedureInfo.numOfVisits}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )} */}
          </Grid>
        </Card>
        {showOrderSelect && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Order Select Information</Typography>
            </Box>
            <Card
              variant="outlined"
              sx={{
                ...priorAuthStyles.procedureCard,
              }}
            >
              {crdResponse &&
                crdResponse.cards &&
                crdResponse.cards.map((card, index) => (
                  <Alert severity={card.indicator} key={index}>
                    {card.summary && (
                      <Typography component="p" sx={{ fontWeight: "bold" }}>
                        {card.summary}
                      </Typography>
                    )}
                    {card.detail && (
                      <Typography component="p">{card.detail}</Typography>
                    )}
                  </Alert>
                ))}
            </Card>
          </>
        )}
        <Grid item container xs={12} mt={2} sx={{ justifyContent: "center" }}>
          <Button
            variant="outlined"
            sx={{
              ...priorAuthStyles.reviewButton,
            }}
            type="submit"
          >
            <Typography variant="body1" sx={{ ...priorAuthStyles.inputColor }}>
              Review & Submit
            </Typography>
          </Button>
        </Grid>
      </Box>
    </form>
  );
};

export default Procedures;
