import React, { useState } from "react";
import { Box } from "@mui/material";
import EncounterHeader from "./EncounterHeader";
import EncounterTabs from "./EncounterTabs"

const EncounterContent = (props) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <EncounterHeader />
            <EncounterTabs />
        </Box>
    )

}

export default EncounterContent;