// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/callback/Callback.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;EACA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,kEAAkE;EACpE;EACA;IACE,SAAS;IACT,uBAAuB;EACzB;EACA;IACE,UAAU;IACV,uBAAuB;EACzB;EACA;IACE,UAAU;IACV,kBAAkB;EACpB;EACA;IACE;MACE,QAAQ;MACR,YAAY;IACd;IACA;MACE,SAAS;MACT,YAAY;IACd;EACF","sourcesContent":[".lds-facebook {\n    display: inline-block;\n    position: relative;\n    width: 80px;\n    height: 80px;\n  }\n  .lds-facebook div {\n    display: inline-block;\n    position: absolute;\n    left: 8px;\n    width: 16px;\n    background: #fff;\n    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;\n  }\n  .lds-facebook div:nth-child(1) {\n    left: 8px;\n    animation-delay: -0.24s;\n  }\n  .lds-facebook div:nth-child(2) {\n    left: 32px;\n    animation-delay: -0.12s;\n  }\n  .lds-facebook div:nth-child(3) {\n    left: 56px;\n    animation-delay: 0;\n  }\n  @keyframes lds-facebook {\n    0% {\n      top: 8px;\n      height: 64px;\n    }\n    50%, 100% {\n      top: 24px;\n      height: 32px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
