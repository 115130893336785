import {
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import EnhancedTableHead from "./EnhancedTableHead";
import { styled } from "@mui/material/styles";

const PaginationContainer = styled("div")`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #000053;
  color: white;
  width: 100%;
  z-index: 1;
`;

const TableContainerStyled = styled(TableContainer)`
  max-width: 100vw;
  height: 68vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const EnhancedTable = (props) => {
  const {
    headers,
    rows,
    onRowClick,
    page = 1,
    totalPages = 1,
    onChange,
    tableHeight,
    loading,
  } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowClick = async (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage != currentPage) {
      setCurrentPage(newPage);
      onChange(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    onChange(1, event.target.value);
  };

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page]);

  return (
    <>
      <TableContainerStyled
        component={Paper}
        sx={{
          ml: "2%",
          backgroundColor: "inherit",
          padding: "0px",
          border: "1px solid white",
          height: tableHeight ?? "68vh",
        }}
      >
        <Table
          sx={{
            backgroundColor: "inherit",
            marginTop: "0em",
            width: "100%",
          }}
          aria-label="simple table"
        >
          <EnhancedTableHead headers={headers} />
          <TableBody>
            {loading ? (
              <TableRow className="tableCellBody" sx={{ height: "50vh" }}>
                <TableCell align="center" colSpan={headers.length}>
                  <CircularProgress size={50} />
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow className="tableCellBody" sx={{ height: "50vh" }}>
                <TableCell align="center" colSpan={headers.length}>
                  <span>No Record Found</span>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(row)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#0E1046",
                    },
                  }}
                >
                  {headers.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align="center">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <PaginationContainer>
          <div>
            <span>Rows per page:</span>
            <FormControl
              variant="standard"
              sx={{ width: "68px", paddingLeft: "10px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                sx={{
                  ".css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
                    color: "white",
                  },
                  ".css-7n73i8-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
                    {
                      color: "white",
                      fontSize: "medium",
                    },
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Pagination
            count={totalPages}
            color="secondary"
            page={currentPage}
            onChange={handleChangePage}
            sx={{
              ".css-xafqdl-MuiPaginationItem-root, .css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root":
                {
                  color: "white",
                },
              ".css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                {
                  color: "black",
                },
              ".css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected.Mui-disabled":
                {
                  color: "white",
                },
            }}
            disabled={totalPages == 1}
          />
        </PaginationContainer>
      </TableContainerStyled>
    </>
  );
};

export default EnhancedTable;
