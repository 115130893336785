import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import Cookies from "js-cookie";
import { getAuth } from "../../api/api";
import './Callback.css';
import { Typography } from '@mui/material';
import { ACCESS_TOKEN_STORAGE_KEY, ID_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '../../util/constants';

const Callback = () => {
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const exchangeCodeForToken = async (code) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_ACCESS_TOKEN_URL}`, new URLSearchParams({
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: `${window.location.origin}/oauth/callback`
                }),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Authorization": `Basic ${btoa(`${process.env.REACT_APP_EPA_CLIENT_ID}:${process.env.REACT_APP_EPA_CLIENT_SECRET}`)}`
                        }
                    },
                );

                const { access_token, refresh_token, id_token } = response.data;
                console.log('access_token', access_token)
                console.log('refresh_token', refresh_token)
                console.log('id_token', id_token)

                // Save tokens securely (e.g., in local storage or cookies)
                Cookies.set(ACCESS_TOKEN_STORAGE_KEY, access_token)
                if (refresh_token) {
                    Cookies.set(REFRESH_TOKEN_STORAGE_KEY, refresh_token)
                }
                if (id_token) {
                    Cookies.set(ID_TOKEN_STORAGE_KEY, id_token);
                }
                
                await getAuth();
                navigate('/dashboard');
            } catch (error) {
                console.error('Error exchanging code for token:', error);
                // Handle error (e.g., show an error message, retry, etc.)
            }
        };
        console.log('Callback component mounted');
        const { code } = queryString.parse(location.search);
        console.log('Code from URL:', code);

        if (code) {
            exchangeCodeForToken(code);
        } else {
            console.error('OAuth code not found in URL');
        }
    }, [location, navigate]);

    return (
        <div style={{ background: "#000235", display: "grid", height: "100vh", justifyContent: "center", alignItems: "center" }}>
            <div className="lds-facebook" style={{placeSelf: "flex-end center"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div style={{placeSelf: "flex-start center"}}>
                <Typography variant='h5' style={{ color: "white" }}>
                    Please wait while we Load your data
                </Typography>
            </div>
        </div>
    )
};

export default Callback;
