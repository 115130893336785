import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const SignInDialog = (props) => {
  const {
    open,
    setOpen,
    setOpenSignUp,
    handleLogin,
    setUsername,
    setPassword,
    error,
    errorMsg,
    setShowError,
    disabled,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#ABDDDD",
          borderRadius: 12,
          minWidth: 400,
          minHeight: 400,
        },
      }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {error && (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                }}
              >
                <CloseOutlinedIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg}
          </Alert>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Outfit",
              color: "black",
              fontWeight: 600,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            SIGN IN
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* <form onSubmit={handleLogin}> */}
        <Card
          variant="outlined"
          sx={{
            borderRadius: 15,
            m: 1,
            backgroundColor: "#d9d9d91a",
            border: "1px solid",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <TextField
              required
              id="standard-username-input"
              type="text"
              placeholder="Username"
              autoComplete="username"
              variant="standard"
              sx={{
                p: 2,
                width: "90%",
              }}
              inputProps={{
                style: { color: "black" },
              }}
              onChange={(event) => setUsername(event.target.value)}
            />
            <FormControl sx={{ p: 2, width: "90%" }} variant="standard">
              <Input
                required
                id="standard-adornment-password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                onChange={(event) => setPassword(event.target.value)}
                sx={{ color: "black" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon color="error" />
                      ) : (
                        <VisibilityIcon color="default" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 10,
                border: "2px solid black",
                width: 150,
                mt: 3,
              }}
              type="submit"
              onClick={handleLogin}
              disabled={disabled}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: "Outfit", color: "black" }}
              >
                Sign In
              </Typography>
            </Button>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "center" }}
          ></CardActions>
        </Card>
        {/* </form> */}
        <Card
          variant="outlined"
          sx={{
            borderRadius: 18,
            m: 1,
            backgroundColor: "#d9d9d91a",
            border: "1px solid",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontFamily: "Outfit" }}>
              Don’t Have an account yet?
              <br />
              <span
                onClick={() => {
                  setOpen(false);
                  setOpenSignUp(true);
                }}
                className="pointer"
                style={{ color: "#8d8dda", fontWeight: 900 }}
              >
                CLICK HERE
              </span>{" "}
              to Sign Up!
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;
