import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { newPatients } from "../../util/constants";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import NewAuthSearchContent from "./NewAuthSearchContent";
import NewAuthTypeContent from "./NewAuthTypeContent";
import { fetchPatients, patientSearch } from "../../api/api";

const NewAuthDialog = (props) => {
  const { open, setOpen } = props;
  const [showAuthType, setShowAuthType] = useState(false);
  const [patient, setPatient] = useState(null);
  const [patientSearchList, setPatList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = localStorage.getItem("user");
  const [memberSearch, setMemberSearch] = useState({
    firstName: '',
    lastName: '',
  })
  
  const fetchPatientsList = async (queryParams = '') => {
    setIsLoading(true);
    const data = await patientSearch(user, queryParams);
    setPatList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPatientsList();
  }, []);

  const handleChange = (event, value) => {
    setPatient(value || null);
  };
  
  const handleSearch = async (value, field) => {
    // if (filterTimeout) {
    //   clearTimeout(filterTimeout)
      
    // }
    setMemberSearch(prevState => ({ ...prevState, [field]: value }));
      
      // filterTimeout = setTimeout(async () => {
       const updatedMemberSearch = { ...memberSearch, [field]: value };
       
        let queryParams = "";
         if(updatedMemberSearch.firstName) {
             queryParams += `&firstName=${updatedMemberSearch.firstName}`
      }
         if(updatedMemberSearch.lastName) {
        queryParams += `&lastName=${updatedMemberSearch.lastName}`
      }
      if (queryParams) {
        queryParams = "?" + queryParams.slice(1);
      }

      fetchPatientsList(queryParams);
      // }, 500)

      
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowAuthType(true);
  } 

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#8D8DDA",
          borderRadius: 12,
          minWidth: 400,
          minHeight: 400,
        },
      }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showAuthType && (
          <IconButton onClick={() => setShowAuthType(false)}>
            <KeyboardBackspaceOutlinedIcon />
          </IconButton>
        )}
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Outfit",
            color: "black",
            fontWeight: 400,
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          Add New Authorization
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <HighlightOffOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            borderRadius: 15,
            m: 1,
            backgroundColor: "#8D8DDA",
            border: "2px solid black",
          }}
        >
          {!showAuthType ? (
            <NewAuthSearchContent
              setShowAuthType={setShowAuthType}
              handleChange={handleChange}
              handleSearch={handleSearch}
              patient={patient}
              patientSearchList={patientSearchList}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          ) : (
            <NewAuthTypeContent patient={patient} />
          )}
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default NewAuthDialog;
