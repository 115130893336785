import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PriorAuthStepper from "./PriorAuthStepper";
import PriorAuthRequestForm from "./PriorAuthRequestForm";
import {
  createCrd,
  fetchOrderForm,
  fetchPatientsCoverage,
  fetchProvider,
} from "../../api/api";
import { useState } from "react";
import { useEffect } from "react";
import PriorAuthResponseCard from "./PriorAuthResponseCard";
import { FhirJsonForm, FhirJsonResp } from "fhirformjs";
import PriorAuthQuestionnaire from "./PriorAuthQuestionnaire";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ErrorDialogSlide from "../../util/ErrorDialog";

const PriorAuthBody = () => {
  const [showProcedures, setShowProcedures] = useState(false);
  const [value, setValue] = useState("");
  const [showRequestForm, setShowForm] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [patient, setPatient] = useState({});
  const [provider, setProvider] = useState({});
  const [crdResponse, setCrdResponse] = useState({});
  const [selectedProc, setSelectedProc] = useState({
    icd10: "",
    code: "",
    description: "",
    dxDesc: "",
    category: "",
  });
  const [selectedOrg, setSelectedOrg] = useState({
    practRoleId: "",
    orgId: "",
    orgName: "",
  });
  const [appointmentDate, setAppointmentDate] = useState(new Date().toString());
  const [startDate, setStartDate] = useState(new Date().toString());
  const [endDate, setEndDate] = useState(new Date().toString());
  const [appointmentType, setAppointmentType] = useState("");
  const [showOrderSelect, setShowOrderSelect] = useState(false);
  const selectedPatient = localStorage.getItem("selectedPatient");
  const [priorAuthRequired, setRequired] = useState(false);
  const [orderLink, setOrderLink] = useState([]);
  const [reqDocumentLink, setReqDocumentLink] = useState("");
  const [selectedHook, setSelectedHook] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const user = localStorage.getItem("user");
  const encounter_id = sessionStorage.getItem("en_id");
  const [questionnaire, setQuestionnaire] = useState();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [crdErrors, setCrdErrors] = useState({});
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const navigate = useNavigate();

  const fetchStepperContent = async () => {
    const data = await fetchPatientsCoverage(user, selectedPatient);
    const provData = await fetchProvider(user);
    setPatient(data);
    setProvider(provData);
  };

  useEffect(() => {
    fetchStepperContent();
  }, []);

  useEffect(() => {
    if (showRequestForm) {
      setLoading(false);
    }
  }, [showRequestForm]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedProc({
      icd10: "",
      code: "",
      description: "",
      dxDesc: "",
      category: "",
    });
    setAppointmentType("")
    setShowProcedures(true);
  };

  const handleCheckBoxChange = () => {
    setChecked(!checked);
  };

  const handleCRDrequest = async (
    value,
    patient,
    provider,
    selectedProc,
    selectedHook
  ) => {
    setLoading(true);
    const formattedRequest = {
      memberId: patient.memberId,
      providerId: provider.providerId,
      coverageId: patient.coverageId,
      payorId: patient.orgRef,
      cpt: selectedProc.code,
      // icd10: selectedProc.icd10,
      // dxDesc: selectedProc.dxDesc,
      category: value,
      display: selectedProc.description,
      userId: user,
      hook: selectedHook,
      appointmentType: appointmentType,
      appointmentDate: appointmentDate,
      startDate: startDate,
      endDate: endDate,
      encounterId: encounter_id,
      isperformer: checked,
      practRoleId: selectedOrg.practRoleId,
      organizationId: selectedOrg.orgId,
    };
    const data = await createCrd(formattedRequest);

    if (data?.errors) {
      setCrdErrors(data);
      setOpenErrorDialog(true);
      return;
    }
    setCrdResponse(data);

    if (selectedHook === "order-select") {
      if (data && data.cards && data.cards.length > 0) {
        setShowOrderSelect(true);
      } else {
        setShowOrderSelect(false);
        console.error("No data available or data structure is incorrect");
      }
    } else if (
      selectedHook === "order-sign" ||
      selectedHook === "appointment-book"
    ) {
      if (data && data.cards) {
        if (data.cards[0].links) {
          setRequired(true);
          const linksData = data.cards[0].links;
          const documentationLink = linksData.find(
            (link) => link.label === "Documentation Requirements"
          );
          const orderFormLink = linksData.filter(
            (link) => link.label !== "Documentation Requirements"
          );

          if (documentationLink) setReqDocumentLink(documentationLink.url);
          if (orderFormLink) setOrderLink(orderFormLink);
        }
      }
      setShowProcedures(false);
      setShowForm(false);
      setShowAlert(true);
    }
    setLoading(false);
  };

  const handleClick = async (required, link, submitted) => {
    if (required && !submitted) {
      const data = await fetchOrderForm(user);
      const resp = FhirJsonForm(data);
      // const respData = FhirJsonResp(resp.model, {}, resp.schema)
      setQuestionnaire(resp);
      setSubmitted(true);
      window.open(link, "_blank");
      // setShowQR(true);
    }
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 3,
          // ml: "4%",
          p: "3%",
          minWidth: "90vw",
          minHeight: "80vh",
          backgroundColor: "inherit",
          border: "1px solid white",
        }}
      >
        {showRequestForm ? (
          <PriorAuthRequestForm
            value={value}
            patient={patient}
            provider={provider}
            selectedProc={selectedProc}
            appointmentDate={appointmentDate}
            renderingProvider={checked}
          />
        ) : showAlert ? (
          <PriorAuthResponseCard
            patient={patient}
            provider={provider}
            priorAuthRequired={priorAuthRequired}
            crdResponse={crdResponse}
            orderLink={orderLink}
            reqDocumentLink={reqDocumentLink}
            handleClick={handleClick}
            submitted={submitted}
          />
        ) : (
          /* : showQR ? (
              <PriorAuthQuestionnaire questionnaire={questionnaire}/>
            ) */
          <PriorAuthStepper
            showProcedures={showProcedures}
            value={value}
            handleChange={handleChange}
            patient={patient}
            provider={provider}
            setSelectedProc={setSelectedProc}
            selectedProc={selectedProc}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            showOrderSelect={showOrderSelect}
            setSelectedHook={setSelectedHook}
            handleCRDrequest={handleCRDrequest}
            crdResponse={crdResponse}
            appointmentDate={appointmentDate}
            setAppointmentDate={setAppointmentDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            appointmentType={appointmentType}
            setAppointmentType={setAppointmentType}
            setShowForm={setShowForm}
            setShowProcedures={setShowProcedures}
            checked={checked}
            handleCheckBoxChange={handleCheckBoxChange}
          />
        )}
        {showRequestForm ? (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={() => {
                  setShowProcedures(true);
                  setShowForm(false);
                }}
                variant="outlined"
                sx={{
                  border: "2px solid white",
                  backgroundColor: "inherit",
                  borderRadius: 10,
                  p: "1em 2em 1em 2em",
                }}
              >
                <Typography variant="body1" sx={{ color: "white" }}>
                  Back
                </Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  border: "2px solid white",
                  backgroundColor: "inherit",
                  borderRadius: 10,
                  p: "1em 2em 1em 2em",
                }}
                onClick={() => {
                  setShowProcedures(true);
                  setShowForm(false);
                  navigate("/priorAuth");
                }}
              >
                <Typography variant="body1" sx={{ color: "white" }}>
                  Edit
                </Typography>
              </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  handleCRDrequest(
                    value,
                    patient,
                    provider,
                    selectedProc,
                    selectedHook
                  );
                }}
                variant="outlined"
                sx={{
                  border: "2px solid white",
                  backgroundColor: "inherit",
                  borderRadius: 10,
                  p: "1em 2em 1em 2em",
                }}
                disabled={loading}
              >
                <Typography variant="body1" sx={{ color: "white" }}>
                  {loading ? (
                    <span>
                      Submitting...
                      <CircularProgress size={15} color="inherit" />
                    </span>
                  ) : (
                    "Submit"
                  )}
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : undefined}
      </Paper>
      {/* {showAlert && (
          <Alert 
            variant="filled" 
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseOutlinedIcon fontSize="inherit" />
              </IconButton>
            }
            >
            <Typography variant="h6">
              {priorAuthMsg}
            </Typography>
          </Alert>
        )} */}
      {crdErrors && openErrorDialog ? (
        <ErrorDialogSlide
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          errors={crdErrors.errors.errors}
          status={crdErrors.status}
        />
      ) : null}
    </>
  );
};

export default PriorAuthBody;
