export const priorAuthStyles ={
    bodyText:{
        display: 'flex', 
        justifyContent: 'flex-start',
         gap: 1 
    },
    columnDiv:{
        display: 'flex', 
        flexDirection: 'column'
    },
    rowDiv:{
        display: 'flex', 
        flexDirection: 'row'
    },
    filterCard:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "inherit",
        border: "2px solid #ABD9D9",
        borderRadius: 20,
    },
    flexDiv:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    flexSpaceDiv:{
        display: "flex",
        justifyContent: "space-between",
    },
    flexEvenlyDiv:{
        display: "flex",
        justifyContent: "space-evenly",
    },
    flexStartDiv:{
        display: "flex", 
        justifyContent: "flex-start"
    },
    flexEndDiv:{
        display: "flex", 
        justifyContent: "flex-end"
    },
    outlinePaper:{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: "1rem",
        ml: "4%",
        p: "5%",
        minWidth: "90vw",
        minHeight: "80vh",
        backgroundColor: "inherit",
        border: "1px solid white",
    },
    actionButton:{
        border: "2px solid white",
        backgroundColor: "inherit",
        borderRadius: 10,
        p: "1em 2em 1em 2em",
    },
    requestFormPaper:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFDFD",
        p: 2,
        gap: 2,
        width: '80%',
        ml: '10%'
    },
    bodyFont:{
        fontWeight: 600 
    },
    bodyWeight:{
        fontWeight: "bold"
    },
    inputColor:{
        color:'white'
    },
    responseCardPaper:{
        padding: "1rem",
        borderRadius: ".5rem",
        display: "flex",
        flexDirection: "column",
        border: '1px solid white',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none'
    },
    cardBody:{
        height: '4rem',
        flexShrink: 0,
        width: '20rem',
        margin: '1rem',
        border: '1px solid #ABD9D9',
        borderRadius: '1rem'
    },
    cardSummary:{
        color: 'white', 
        whiteSpace: 'nowrap',
         overflow: 'hidden', 
         textOverflow: 'ellipsis'
    },
    cardPaper:{
        padding: "1rem",
        borderRadius: ".5rem",
        display: "flex",
        flexDirection: "column",
        border: '1px solid white',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: 'none'
    },
    spanBorder:{
        width: 400, 
        borderBottom: "1px solid #ABDDDD"
    },
    procedureCard:{
        display: "flex",
        border: "1px solid white",
        backgroundColor: "#181838",
        borderRadius: 2,
        width: "100%",
        height: "fit-content",
        flexDirection: 'column',
        padding: '1rem',
        gap: 1
    },
    reviewButton:{
        border: "2px solid white",
        backgroundColor: "inherit",
        borderRadius: 10,
        p: 1.5,
    },
    stepperContentPaper:{
        display: "flex",
        gap: 30,
        flexDirection: "row",
        m: "2%",
        minWidth: "80vw",
        backgroundColor: "inherit",
        border: "1px solid white",
    },
    stepperContentPaper_3:{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        m: "2%",
        p: "2%",
        minWidth: "70vw",
        minHeight: "50vh",
        backgroundColor: "inherit",
        border: "1px solid white",
    },
    stepperCard:{
        borderRadius: 10,
        border: "1px solid white",
        backgroundColor: "inherit",
        minWidth: "30vw",
    }

   
}