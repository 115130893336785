import logo from './logo.svg';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import theme from '../src/Theme'
import Login from './pages/Login';
import { CssBaseline, ThemeProvider } from '@mui/material';
import NoAccess from './components/NoAcess';
import Dashboard from './pages/Dashboard';
import PriorAuth from './pages/PriorAuth';
import Encounter from './pages/Encounter';
import Callback from './components/callback/Callback';
import PageLayout from './layout/PageLayout';


function App() {

  const allRoutes = {
    dashboard: (
      <Route key='dashboard' path='/dashboard' element={<Dashboard />}/>
    ),
    priorAuth: (
      <Route key='priorauth' path='/priorAuth' element={<PriorAuth />}/>
    ),
    encounter:(
      <Route key='encounter' path='/encounter' element={<Encounter />}/>
    )
  };

  const routes = [
    'dashboard',
    'priorAuth',
    'encounter'
  ]

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
              <Route exact path='/' element={<Login />} />
              <Route element={<PageLayout />}>
              {Object.keys(allRoutes)
                      .filter((route) =>
                          (routes
                              ? routes
                              : []
                          ).includes(route),
                      )
                      .map((key) => allRoutes[key])
              }
              </Route>
              <Route key='callback' path='/oauth/callback' element={<Callback />}/>
              <Route exact path="*" element={<NoAccess />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
