import { createTheme } from "@mui/material";

/* const smartLensBlue = "#2B2E7D";
const secondaryMain = "#2E3CDE";
const smartLensWhite = "#F8FAFC"; */
const listItemHover = "linear-gradient(90deg, #AEB4FF 5%, #000863 5%)";

export default createTheme({
  /* palette: {
    common: {
      blue: smartLensBlue,
      white: smartLensWhite,
    },
    primary: {
      main: smartLensBlue,
    },
    secondary: {
      main: secondaryMain,
    },
  }, */
  palette:{
    secondary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: "Outfit",
    h1: {
      fontSize: "100px",
      fontWeight: 400,
    },
    h5: {
      color: 'white'
    },
    h6: {
      color: 'white'
    },
    h9: {
      fontSize: "21px",
      fontWeight: 600,
    },
    h10: {
      fontSize: "18px",
      // fontWeight: 600
    },
    tableCell: {
      fontSize: "16px",
      fontWeight: 600,
    },
    tableHeader: {
        fontSize: "18px",
        fontWeight: 600,
        color: 'white'
      },
    pageTitle: {
      fontSize: "28px",
      fontWeight: 600,
      background: "-webkit-linear-gradient(45deg, #2B2E7D 30%, red 90%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox:{
          background:'#8D8DDA'
        },
        inputFocused:{
          color:'white',
          fontSize:'1.25rem',
        },
        popupIndicator:{
          color:'white'
        },
        clearIndicator:{
          color:'white'
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
         color:'white',
         fontSize:'1.25rem',
        },
        showDatePicker:{
          color:'white'
        }
      },
    },
    
    /* MuiTextField: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiLink: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ":hover": {
            background: listItemHover,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderStyle: "none",
        },
        root: {
          borderRadius: "16px",
          background: "#F1F5F9",
          marginRight: "30px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "#EDEFFF",
          },
        },
      },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                "&:hover": {
                    color: "white",
                  },
                //   "&:focus" : {
                //     color: "#EDEFFF",
                //   }
            }
        }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          "&:hover": {
            background: "#EDEFFF",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "linear-gradient(to right, #666aca , #F1F5F9)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          color: "#1c1e50",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
        },
      },
    }, */
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: "none",
            color: 'white',
            fontFamily: 'Outfit',
            fontSize: '16px'
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
            root: {
                padding: '1em 2em 1em 2em'
            }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: 'white'
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
            paper: {
              background: "#8D8DDA",
            }
        }
      }
    /* MuiTooltip: {
            styleOverrides: {
              tooltip: {
                //backgroundColor: '#2B2E7D',
                color: 'white',
                border: '1px solid #2B2E7D',
                borderRadius: '10px'
              },
              arrow: {
                '&:before': {
                    border: '1px solid #2B2E7D',
                },
                color: '#2B2E7D',
            },
            },
        } */
  },
});
