import React from "react";
import { Box, Typography } from "@mui/material";
// import Form from '@rjsf/core';
// import validator from '@rjsf/validator-ajv6';

const PriorAuthQuestionnaire = (props) => {

    const { questionnaire } = props;
    const numbers = [1, 3, 4, 5, 6, 7, 8,   9, 10, 'PND'];

    function CustomFieldTemplate(props) {
        const {id, classNames, label, help, required, description, errors, children, schema} = props;
        console.log(props);
        console.log(schema.properties[1]);
        return (
          <div>
            {/* {numbers.map(item => <Typography variant="h5">{schema.properties[item].title}</Typography>)} */}
            <Typography variant="body1">{children}</Typography>
            {/* {errors} */}
            {/* {help} */}
          </div>
        );
      }

      const uiSchema = {
        "ui:FieldTemplate": CustomFieldTemplate
      }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", color: 'white', fontSize: 20, backgroundColor: '#D9D9D933' }}>
            {/* <Form schema={questionnaire.schema}
                    validator={validator}
                    uiSchema={questionnaire.uiSchema}
                    liveValidate
                    //   FieldTemplate={() =>    CustomFieldTemplate(props)}
                // formData={formData}
                // onSubmit={e => handleSubmit(e.formData)}
                /> */}
        </Box>
    )
}

export default PriorAuthQuestionnaire;