import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import Header from "./header/Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/Back.svg";

export const NoAccess = () => {

  const navigate = useNavigate();

  return (
    <Header
      children={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: 'center', color: 'orange' }}>
            <Box
              sx={{
                p: '10%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              <Typography variant="h1">
                404
              </Typography>
              <Typography variant="h2">Not Accessible</Typography>
              <Typography variant="h5">
                Sorry, This page cannot be displayed
              </Typography>
              <IconButton onClick={() => navigate(-1)}>
                <BackArrow />
              </IconButton>
              <Typography variant="h6">Go back to the previous page</Typography>
            </Box>
          </Box>
          <Footer />
        </Box>
      }
    />
  );
};

export default NoAccess;
