import { Autocomplete, Box, Button, CardContent, CircularProgress, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";

const NewAuthSearchContent = (props) => {

    const { handleChange, handleSearch, patient, patientSearchList, isLoading,handleSubmit } = props;
    const [memberInput, setMemberInput] = useState("");
    return (
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              p: 5
            }}
          >
        <Typography variant="body1" sx={{color: "white"}}>Search a Member</Typography>
        <form onSubmit={handleSubmit}>
            <Box sx={{display: 'flex', justifyContent: 'center', gap: 2}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <TextField
                        id="standard-firstname-input"
                        type="text"
                        placeholder="First Name"
                        autoComplete="firstname"
                        variant="standard"
                        onChange={event => handleSearch(event.target.value, "firstName")}
                        sx={{ p: 2, width: "90%", input: { color: 'white' }, '& .MuiInput-underline:after': { borderBottomColor: 'black',} }}
                        />
                    <TextField
                        id="standard-lastname-input"
                        type="text"
                        placeholder="Last Name"
                        autoComplete="lastname"
                        variant="standard"
                        onChange={event => handleSearch(event.target.value, "lastName")}
                        sx={{ p: 2, width: "90%", input: { color: 'white' }, '& .MuiInput-underline:after': { borderBottomColor: 'black',} }}
                        />
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    {/* <TextField
                        id="standard-mrn-input"
                        type="text"
                        placeholder="MRN"
                        autoComplete="mrn"
                        variant="standard"
                        onChange={event => handleSearch(event.target.value, "mrn")}
                        sx={{ p: 2, width: "90%", input: { color: 'white' } }}
                        />
                    <TextField
                        id="standard-memberid-input"
                        type="text"
                        placeholder="Member ID"
                        autoComplete="memberid"
                        variant="standard"
                        onChange={event => handleSearch(event.target.value, "_id")}
                        sx={{ p: 2, width: "90%", input: { color: 'white' } }}
                        />*/}
                </Box>
            </Box>
            {/* <FormControl variant="standard" sx={{ alignItems: 'center', m: 3, minWidth: '100%' }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={patient}
                    onChange={handleChange}
                    label="Age"
                    fullWidth
                    sx={{ width: "90%", color: "white" }}
                >
                {patientSearchList && patientSearchList.length > 0 && patientSearchList.map(item => (
                    <MenuItem value={`${item.memberId} + ${item.lastName} + ${item.firstName} + ${item.mrn}`}>
                        {item.memberId} - {item.lastName}, {item.firstName} - {item.mrn}
                    </MenuItem>
                ))}
                </Select>
            </FormControl> */}

<Autocomplete
      id="asynchronous-demo"
      sx={{ 
         width: "90%",
         marginLeft: 2,
         '& label.Mui-focused': {
              color: 'black',
          },
        '& .MuiInput-underline:after': {
              borderBottomColor: 'black',
          },
        }}
      value={patient}
      inputValue={memberInput}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setMemberInput(newInputValue);
      }}
      
      isOptionEqualToValue={(option, value) => {
        if (!value) return false;  
        return (
          option.memberId === value.memberId &&
          option.lastName === value.lastName &&
          option.firstName === value.firstName &&
          option.mrn === value.mrn
        );
        }
      }
      getOptionLabel={(option) => `${option.memberId} - ${option.lastName}, ${option.firstName} - ${option.mrn}`}
      options={patientSearchList}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Member List"
          variant="standard"
          sx={{
            '& .MuiFormLabel-root': {
              color: 'white'
            },
        }}
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }
        }
        />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ color: 'white'}}>
          {`${option.memberId} - ${option.lastName}, ${option.firstName} - ${option.mrn}`}
        </li>
      )}
    />

            <Button
              variant="outlined"
              sx={{
                borderRadius: 10,
                border: "2px solid black",
                width: 150,
                mt: 3,
              }}
              type="submit"
              // onClick={() => setShowAuthType(true)}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: "Outfit", color: "white" }}
              >
                Confirm
              </Typography>
            </Button>
            </form>
        </CardContent>
    )

}

export default NewAuthSearchContent;