import {
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import EnhancedTableHead from "../EnhancedTableHead";
import { styled } from "@mui/material/styles";

const PaginationContainer = styled("div")`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #000053;
  color: white;
  width: 100%;
  z-index: 1;
`;

const TableContainerStyled = styled(TableContainer)`
  max-width: 100vw;
  height: 68vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const DashboardTable = (props) => {
  const {
    tableData = [],
    data,
    searchParam,
    setOpenPatientView,
    setSelectedRowData,
    page = 1,
    totalPages = 1,
    onChange,
    loading,
  } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Id");
  const [sortArray, setSortArray] = useState([]);
  const [sortOrderArray, setSortOrderArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleRowClick = async (row) => {
    setSelectedRowData(row);
    setOpenPatientView(true);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    onChange(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    onChange(1, event.target.value);
  };

  const handleRequestSort = (event, property, rowsPerPage, searchParam) => {
    sortArray.push(orderBy);
    sortOrderArray.push(order);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const filteredArray = tableData.length > 0 && tableData.filter((row) => {
      const Id = row.memberId.toLowerCase();
      const name = row.firstName.toLowerCase();
      const lastname = row.lastName.toLowerCase();
      const memberId = row.memberId.toLowerCase();
      const search = searchParam.toLowerCase();
      return (
        Id.includes(search) ||
        name.includes(search) ||
        lastname.includes(search) ||
        memberId.includes(search)
      );
    });
    setFilteredData(filteredArray);
  }, [tableData, searchParam]);

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page]);

  return (
    <>
      <TableContainerStyled
        component={Paper}
        sx={{
          ml: "4%",
          backgroundColor: "inherit",
          padding: "0px",
          border: "1px solid white",
        }}
      >
        <Table
          sx={{
            backgroundColor: "inherit",
            marginTop: "1em",
            width: "88vw",
          }}
          aria-label="simple table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(event, property, rowsPerPage, searchParam)
            }
            rowCount={tableData && tableData.length}
            rowsPerPage={rowsPerPage}
            setOrderBy={setOrderBy}
          />
          <TableBody>
            {loading ? (
              <TableRow className="tableCellBody" sx={{ height: "50vh" }}>
                <TableCell
                  align="center"
                  colSpan={tableData[0] ? Object.keys(tableData[0]).length : 8}
                >
                  <CircularProgress size={50} />
                </TableCell>
              </TableRow>
            ) : !filteredData ? (
              <TableRow className="tableCellBody" sx={{ height: "50vh" }}>
                <TableCell
                  align="center"
                  colSpan={tableData[0] ? Object.keys(tableData[0]).length : 8}
                >
                  <span>No Record Found</span>
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row) => (
                <TableRow key={row.id} onClick={() => handleRowClick(row)} sx={{cursor:"pointer","&:hover": {
                  backgroundColor: "#0E1046"
                }}}>
                  <TableCell align="center">{row.memberId}</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <span>{`${row.firstName}`}</span>
                    <span>{`${row.lastName}`}</span>
                  </TableCell>
                  <TableCell align="center">{row.mrn}</TableCell>
                  <TableCell align="center">{row.dateOfBirth}</TableCell>
                  <TableCell align="center">{row.gender}</TableCell>
                  <TableCell align="center">{row.authType}</TableCell>
                  <TableCell align="center">{row.submittedDate}</TableCell>
                  <TableCell align="center">{row.lastUpdated}</TableCell>
                  <TableCell align="center">
                    <Chip
                      icon={row.icon}
                      label={row.status}
                      variant="outlined"
                      size="small"
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        backgroundColor: row.color,
                        border: "none",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <PaginationContainer>
          <div>
            <span>Rows per page:</span>
            <FormControl
              variant="standard"
              sx={{ width: "68px", paddingLeft: "10px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                sx={{
                  ".css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
                    color: "white",
                  },
                  ".css-7n73i8-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
                    {
                      color: "white",
                      fontSize: "medium",
                    },
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Pagination
            count={totalPages}
            color="secondary"
            page={currentPage}
            onChange={handleChangePage}
            sx={{
              ".css-xafqdl-MuiPaginationItem-root, .css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root":
                {
                  color: "white",
                },
              ".css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                {
                  color: "black",
                },
                ".css-1lsjcgq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected.Mui-disabled" : {
                  color: "white",
                }
            }}
            disabled={totalPages === currentPage}
          />
        </PaginationContainer>
      </TableContainerStyled>
    </>
  );
};

export default DashboardTable;
