import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import Slide from "@mui/material/Slide";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Card, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function ErrorDialogSlide({
  open,
  setOpen,
  errors,
  status,
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate("/dashboard");
  };

  console.log(errors);

  return (

    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth={true}
      maxWidth={"md"}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#000235",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "white",
          backgroundColor: "rgb(61, 61, 166,0.4)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            color: "red",
            gap: 1,
            fontWeight: "bold",
          }}
        >
          <CancelIcon />
          <Typography>ERROR {status || 400}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "1rem !important" }}>
        <Box
          sx={{
            padding: "10px 0px",
            // border: '1px solid white',
            backgroundColor: "unset",
          }}
        >

          <Paper
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              border: "2px solid white",
              boxShadow: "none",
              backgroundColor: "unset",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "flex-start",
                // alignItems: 'flex-start',
                color: "white",
                // gap: 2,
              }}
            >
              {errors && status === 400
                ? Object.entries(errors).map(([key, value]) => {
                    // if (key === "invalidTypeFields") {
                    //   return (
                    //     <Box key={key}>
                    //       <h4>{key}</h4>
                    //       <ul>
                    //         {value.map((item, index) => (
                    //           <li key={index}>{item.message}</li>
                    //         ))}
                    //       </ul>
                    //     </Box>
                    //   );
                    // }
                    return (
                      <Box key={key}>
                        <h4>{key}</h4>
                        <ul>
                          <li key={key}>
                            {Array.isArray(value) ? value.join(", ") : value}
                          </li>
                        </ul>
                      </Box>
                    );
                  })
                : errors}

            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                component={Button}
                variant="outlined"
                sx={{
                  display: "flex",
                  p: "0.7em 1.5em 0.7em 1.5em",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "inherit",
                  border: "2px solid #ABD9D9",
                  borderRadius: 20,
                  width: "90%",
                  m: "10px 0px",
                }}
                onClick={() => handleClose()}
              >
                {/* <AddOutlined sx={{ color: "white", mr: "3px" }} /> */}
                <Typography variant="h6" sx={{ fontSize: "130%" }}>
                  Try Again
                </Typography>
              </Card>
             
            </Box>
           
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
