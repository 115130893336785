import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { patient_view } from "../../util/constants";
import { createCrd } from "../../api/api";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const PatientViewDialog = (props) => {
  const { openPatientView, setOpenPatientView, selectedRowData } = props;

  const [expanded, setExpanded] = useState(null);
  const [patientResponse, setPatientResponse] = useState({});
  const user = localStorage.getItem("user");
  const pro_id = sessionStorage.getItem("pro_id");
  const [isLoading, SetIsLoading] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleClose = () => {
    setOpenPatientView(false);
  };
  // const uniqueSummaries = {};
  // patient_view.cards.forEach(card => {
  //   if (!(card.summary in uniqueSummaries)) {
  //     uniqueSummaries[card.summary] = [];
  //   }
  //   uniqueSummaries[card.summary].push(card.detail);
  // });

  useEffect(() => {
    const handlePatientCrd = async (selectedRowData) => {
      const formattedRequest = {
        memberId: selectedRowData.memberId,
        providerId: pro_id,
        coverageId: "",
        category: "Patient",
        userId: user,
        hook: "patient-view",
      };
      try {
        const data = await createCrd(formattedRequest);
        if (data) {
          SetIsLoading(false);
        }
        setPatientResponse(data);
      } catch (error) {
        console.log(error);
      }
    };
    handlePatientCrd(selectedRowData);
  }, []);

  // const uniqueSummaries = patient_view.cards.reduce((acc, card) => {
  //   if (!(card.summary in acc)) {
  //     acc[card.summary] = [];
  //   }
  //   acc[card.summary].push(card.detail);
  //   return acc;
  // }, {});

  const uniqueSummaries = {};
  if (patientResponse && patientResponse.cards) {
    patientResponse.cards.forEach((card) => {
      if (!(card.summary in uniqueSummaries)) {
        uniqueSummaries[card.summary] = [];
      }
      uniqueSummaries[card.summary].push(card.detail);
    });
  }

  return (
    // <Dialog
    //   open={openPatientView}
    //   // onClose={handleClose}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   fullWidth={true}
    //   maxWidth={"lg"}
    //   sx={{
    //     "& .MuiDialog-paper": {
    //       backgroundColor: "#000235",
    //     },
    //   }}
    // >
    //   <DialogTitle
    //     sx={{
    //       color: "white",
    //       backgroundColor: "rgb(61, 61, 166,0.4)",
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //     }}
    //   >
    //     <Typography
    //       sx={{
    //         color: "white",
    //         flexGrow: 1,
    //         paddingLeft: "1rem",
    //       }}
    //     >
    //       Patient Details
    //     </Typography>
    //     <IconButton onClick={() => handleClose()}>
    //       <HighlightOffOutlinedIcon sx={{ color: "white" }} />
    //     </IconButton>
    //   </DialogTitle>
    //   <DialogContent sx={{ paddingTop: "1rem !important" }}>
    //     <Box
    //       sx={{
    //         padding: "1rem",
    //         // border: '1px solid white',
    //         backgroundColor: "unset",
    //       }}
    //     >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  padding: "0.625rem 1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  border: "2px solid white",
                  boxShadow: "none",
                  backgroundColor: "unset",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        component="p"
                        variant="body1"
                        sx={{ color: "white" }}
                      >
                        <span style={{ fontWeight: "bold" }}>Member ID :</span>{" "}
                        {selectedRowData.memberId}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        component="p"
                        variant="body1"
                        sx={{ color: "white" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Member Name :
                        </span>{" "}
                        {selectedRowData.firstName} {selectedRowData.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        component="p"
                        variant="body1"
                        sx={{ color: "white" }}
                      >
                        <span style={{ fontWeight: "bold" }}>MRN :</span>{" "}
                        {selectedRowData.mrn}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        component="p"
                        variant="body1"
                        sx={{ color: "white" }}
                      >
                        <span style={{ fontWeight: "bold" }}>DOB :</span>{" "}
                        {selectedRowData.dateOfBirth}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        component="p"
                        variant="body1"
                        sx={{ color: "white" }}
                      >
                        <span style={{ fontWeight: "bold" }}>Gender :</span>{" "}
                        {selectedRowData.gender}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {isLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          container
                          sx={{ justifyContent: "center" }}
                        >
                          <Typography
                            component="p"
                            variant="body1"
                            sx={{ color: "white" }}
                          >
                            Please wait....! we are fetching the records
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <div>
                          {Object.keys(uniqueSummaries).map(
                            (summary, index) => (
                              <Accordion
                                key={index}
                                expanded={expanded === `panel-${index}`}
                                onChange={handleChange(`panel-${index}`)}
                                sx={{
                                  backgroundColor: "unset",
                                  border: "1px solid #abd8d8",
                                  borderRadius: "5px",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon sx={{ color: "white" }} />
                                  }
                                  aria-controls={`panel-${index}-content`}
                                  id={`panel-${index}-header`}
                                  sx={{
                                    backgroundColor: "rgba(61, 61, 166, 0.6)",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      width: "33%",
                                      flexShrink: 0,
                                      color: "white",
                                    }}
                                  >
                                    {summary}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    backgroundColor: "#000235",
                                    border: "1px solid #abd8d8",
                                  }}
                                >
                                  <Box>
                                    {uniqueSummaries[summary].map(
                                      (detail, index) => (
                                        <ul>
                                          <li style={{ color: "white" }}>
                                            <Typography
                                              key={index}
                                              sx={{ color: "white" }}
                                            >
                                              {detail}
                                            </Typography>
                                          </li>
                                        </ul>
                                      )
                                    )}
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
    //     </Box>
    //   </DialogContent>
    // </Dialog>
  );
};

export default PatientViewDialog;
