import React from "react";
import Header from "../components/header/Header";
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import EncounterContent from "../components/encounter/EncounterContent"

const Encounter = () => {

    return (
        <Header
            children={
                <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
                    <EncounterContent />
                    <Footer />
                </Box>
            }
        />
    );
};

export default Encounter;
