import { Box, Typography } from "@mui/material";
import React from "react";
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Footer = () => {

    return (
        <>
        {/* <Box  sx={{ display: 'flex', justifyContent: 'center', mt: '10%'}}>
            <span style={{ width: 600, border: '1px solid #ABDDDD'}}/>
        </Box> */}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', pl: '5%', pt: '5%', pb: '5%'  }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <Typography variant="h6" sx={{color: "white", fontFamily: "Outfit", letterSpacing: 5}}>E PRIOR AUTH</Typography>
                    {/* <Typography variant="h7" sx={{color: "white", fontFamily: "Outfit", letterSpacing: 5}}>A Product By Onyx</Typography>
                    <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}> Onyx Technology LLC is a healthcare interoperability solutions<br/> provider.</Typography> */}
                </Box>
                <Box sx={{display: 'flex', float: 'right', gap: 10, ml: '20%'}}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>
                      <Typography variant="h7" sx={{color: "white", fontFamily: "Outfit", letterSpacing: 5}}>LEGAL</Typography>
                      <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}>Terms of Service</Typography>
                      <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}>Privacy Policy</Typography>
                      <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}>CARIN Code of Conduct</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>
                      <Typography variant="h7" sx={{color: "white", fontFamily: "Outfit", letterSpacing: 5}}>ALSO VISIT</Typography>
                      <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}>SAFHIR</Typography>
                      <Typography variant="subtitle2" sx={{color: "white", fontFamily: "Outfit"}}>Onyx</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Typography variant="h7" sx={{color: "white", fontFamily: "Outfit", letterSpacing: 5}}>CONTACT US </Typography>
                      <Typography variant="subtitle2" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: "white", fontFamily: "Outfit"}}>
                        <span style={{display: 'flex', gap: 5}}><PlaceIcon fontSize="small"/>6518 Meadowridge Rd.,</span>
                        <span style={{display: 'flex', gap: 5}}><Typography color="transparent">NA</Typography>Suite 100, Elkridge,</span>
                        <span style={{display: 'flex', gap: 5}}><Typography color="transparent">NA</Typography>MD 21075</span>
                        <span style={{display: 'flex', gap: 7}}><CallIcon fontSize="small"/>410-782-0476</span>
                        <span style={{display: 'flex', gap: 7}}><MailIcon fontSize="small"/>info@onyxhealth.io</span>
                        <span style={{display: 'flex', gap: 7}}><AccessTimeIcon fontSize="small"/>Monday - Friday</span>
                        <span style={{display: 'flex', gap: 5}}><Typography color="transparent">NA</Typography>9:00AM - 05:00PM</span>
                      </Typography>
                  </Box>
                </Box>
          </Box>
        </>
    )
}

export default Footer;