import { Box, Container } from "@mui/material";
import React from "react";
import { HeaderStyles } from "./HeaderStyles";

const Header = (props) => {

    const { children } = props

    return (
        <Container sx={HeaderStyles.header}>
            <Box sx={HeaderStyles.shadow1} />
            <Box sx={HeaderStyles.shadow2} />
            {children}
        </Container>
    )

}

export default Header;