import { Box, Card, Paper, Typography, Grid } from "@mui/material";
import React from "react";
import { procedureInfo, providers } from "../../util/constants";

const PriorAuthRequestForm = (props) => {
  const {
    value,
    patient,
    provider,
    selectedProc,
    appointmentDate,
    renderingProvider,
  } = props;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFDFD",
        p: 1,
        gap: 2,
        width: { xs: "100%", md: "100%" },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "black",
          fontWeight: 600,
          letterSpacing: 7,
          textAlign: "center",
        }}
      >
        Medical Prior Authorization Request Form
      </Typography>
      <Card variant="outlined" sx={{ backgroundColor: "#B0B0B9", p: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Patient Information
        </Typography>
      </Card>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
          width: "100%",
          border: "2px solid",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Member Information
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Name:</span> {patient.firstName}{" "}
              {patient.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>DOB:</span> {patient.dob}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Gender:</span> {patient.gender}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3.5}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Member ID:</span>{" "}
              {patient.memberId}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Insurance Information
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Name:</span>{" "}
              {patient.insuranceName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Policy ID:</span>
              {patient.policyId ? patient.policyId : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Group ID:</span>
              {patient.groupId ? patient.groupId : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ backgroundColor: "#B0B0B9", p: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Provider Information
        </Typography>
      </Card>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
          width: "100%",
          border: "2px solid",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Faculty
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Name:</span> Dr.{provider.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Department:</span>{" "}
              {providers[0].department}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Requesting Provider
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Name:</span> {provider.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Primary Care Physician:</span>{" "}
              Yes
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>NPI:</span> {provider.npi}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 600 }}>TIN:</span> {provider.tin}
                        </Typography>
                    </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Phone:</span>{" "}
              {provider.phone || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Fax:</span> {provider.fax}
            </Typography>
          </Grid>
        </Grid>
        {renderingProvider && (
          <>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                fontWeight: 600,
                textDecoration: "underline",
              }}
            >
              Rendering Provider
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 600 }}>Name:</span>{" "}
                  {provider.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 600 }}>
                    Primary Care Physician:
                  </span>{" "}
                  Yes
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 600 }}>NPI:</span> {provider.npi}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 600 }}>TIN:</span> {provider.tin}
                        </Typography>
                    </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 600 }}>Phone:</span>{" "}
                  {provider.phone || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 600 }}>Fax:</span> {provider.fax}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      <Card variant="outlined" sx={{ backgroundColor: "#B0B0B9", p: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Procedure Information
        </Typography>
      </Card>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
          width: "100%",
          border: "2px solid",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Service Type:</span> {value}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Date Of Service:</span>{" "}
              {appointmentDate}
            </Typography>
          </Grid>
        </Grid>
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            gap: 2,
            width: "100%",
            border: "2px solid",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="body1">
                <span style={{ fontWeight: 600 }}>ICD9 / ICD10:</span>{" "}
                {selectedProc.icd10}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="body1">
                <span style={{ fontWeight: 600 }}>Description:</span>{" "}
                {selectedProc.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 600 }}>Diagnosis Description:</span>{" "}
                {selectedProc.dxDesc}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <span style={{ fontWeight: 600 }}>CPT / HCPCS:</span>{" "}
                {selectedProc.code}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="body1">
                                <span style={{ fontWeight: 600 }}>Planned Visits From:</span> {procedureInfo.visits}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="body1">
                                <span style={{ fontWeight: 600 }}>Planned Visits To:</span> {procedureInfo.plannedVisits}
                            </Typography>
                        </Grid>
                    </Grid> */}
        </Card>
        {/* <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 600 }}>Number Of Visits:</span> {procedureInfo.numOfVisits}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 600 }}>Frequency:</span> {procedureInfo.frequency}
                        </Typography>
                    </Grid>
                </Grid> */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <span style={{ fontWeight: 600 }}>Additional Notes:</span>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Paper>
  );
};

export default PriorAuthRequestForm;
