import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

const SignButtons = (props) => {

  const { setOpen, setOpenSignUp } = props;
  const [activeButton, setActiveButton] = useState("");
  const handleIDMlogin = () => {
    const clientId = `${process.env.REACT_APP_EPA_CLIENT_ID}`;
    const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}`;
    const scopes = `${process.env.REACT_APP_SCOPE}`
    const authUrl = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scopes}`;
    window.location.href = authUrl;
};
    return (
        <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 2,
              pl: "10%",
            }}
          >
            <Button
              variant={activeButton === "signIn" ? "contained" : "outlined"}
              sx={{
                borderRadius: 10,
                width: 200,
                backgroundColor: activeButton === "signIn" ? "#8d8dda" : "none",
                p: 2,
              }}
              onClick={handleIDMlogin}
            >
              <Typography
                variant="h5"
                sx={{ display: "flex", color: "white", fontFamily: "Outfit" }}
              >
                Sign In
              </Typography>
            </Button>
            {/* <Button
              variant={activeButton === "signOut" ? "contained" : "outlined"}
              sx={{
                borderRadius: 10,
                width: 200,
                backgroundColor:
                  activeButton === "signOut" ? "#8d8dda" : "none",
                p: 2,
              }}
              onClick={() => {setActiveButton("signOut"); setOpenSignUp(true)}}
            >
              <Typography
                variant="h5"
                sx={{ display: "flex", color: "white", fontFamily: "Outfit" }}
              >
                Sign Up
              </Typography>
            </Button> */}
          </Box>
    )

}

export default SignButtons;