import React from "react";
import Header from "../components/header/Header";
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import { newPatients } from "../util/constants";
import DashboardContent from "../components/dashboard/DashboardContent";
import PriorAuthContent from "../components/prior-auth/PriorAuthContent";

const PriorAuth = () => {

    const authType = localStorage.getItem("authType");
    const selectedPatient = localStorage.getItem("selectedPatient");

    console.log(authType, selectedPatient);
    console.log(newPatients.filter(item => item.memberId.trim() === selectedPatient.trim()));

    return (
        <Header
          children={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <PriorAuthContent />
              <Footer />
            </Box>
          }
        />
      );

}

export default PriorAuth;