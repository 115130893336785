import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, Button, useMediaQuery, Chip } from "@mui/material";
import { fetchEnounterService } from "../../api/api";
import { useState, useEffect } from "react";
import AddEncounterModal from "../modal/AddEnounterModal";
import EnhancedTable from "../EnhancedTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import MessagePopUp from "./Popup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: "85%", "@media (max-width: 600px)": { width: "100%" } }}
    >
      {value === index && (
        <Box
          sx={{
            height: "100%",
            paddingBottom: "1rem",
            width: "100%",
            "@media (max-width: 600px)": { padding: "0.5rem" },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const AppointmentHeaders = [
  { id: "FhirId", label: "Appointment ID" },
  { id: "AppointmentService", label: "Appointment Service" },
  { id: "Code", label: "Code" },
  { id: "AppointmentPeriod", label: "Appointment Period" },
  { id: "CreatedOn", label: "Created On" },
  { id: "Status", label: "Status" },
];

const DeviceReqHeaders = [
  { id: "FhirId", label: "Device Req ID" },
  { id: "DeviceRequest", label: "Device Request" },
  { id: "Code", label: "Code" },
  { id: "Intent", label: "Intent" },
  { id: "Requester", label: "Requester" },
  { id: "AuthoredOn", label: "Authored On" },
  { id: "Status", label: "Status" },
];

const EncounterHeaders = [
  { id: "FhirId", label: "Encounter ID" },
  { id: "ClassCodeDisplay", label: "Class Display" },
  { id: "ClassCode", label: "Class Code" },
  { id: "PeriodStart", label: "Period Start" },
  { id: "PeriodEnd", label: "Period End" },
  { id: "TypeCode", label: "Type Code" },
  { id: "TypeDisplay", label: "Type Display" },
  { id: "Status", label: "Status" },
];

const MedReqHeaders = [
  { id: "FhirId", label: "Medication Req ID" },
  { id: "MedicationRequest", label: "Medication Request" },
  { id: "Code", label: "Code" },
  { id: "Intent", label: "Intent" },
  { id: "Requester", label: "Requester" },
  { id: "AuthoredOn", label: "Authored On" },
  { id: "Status", label: "Status" },
];

const ServiceReqHeaders = [
  { id: "FhirId", label: "Service Req ID" },
  { id: "ServiceRequest", label: "Service Request" },
  { id: "Code", label: "Code" },
  // { id: "Intent", label: "Intent" },
  { id: "Requester", label: "Requester" },
  { id: "AuthoredOn", label: "Authored On" },
  { id: "Status", label: "Status" },
  // { id: "Message", label: "Message"}
];

export default function EncounterTabs() {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("selectedTab");
  console.log(selectedTab);

  const [value, setValue] = useState(0);
  const [tab, setTab] = useState("Encounter");
  const [resData, setResData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate({
    //   pathname: "/encounter",
    //   search: `?selectedTab=${tab}`
    // });
  };

  const handleEncounterServices = async (
    resource,
    page = 1,
    rowsPerPage = 10,
    totalPages = 0
  ) => {
    const id = localStorage.getItem("selectedPatient");
    const user = localStorage.getItem("user");
    setLoading(true);
    try {
      const data = await fetchEnounterService(
        user,
        id,
        resource,
        page,
        rowsPerPage,
        totalPages
      );
      setResData(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const createAppointmentRowEntries = (items) => {
    if (items) {
      return items.map((item, index) => ({
        FhirId: item.FhirId,
        AppointmentService: item.AppointmentService,
        Code: item.Code,
        AppointmentPeriod: item.AppointmentPeriod,
        CreatedOn: new Date(item.CreatedOn).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        Status: item.Status,
      }));
    } else {
      return [];
    }
  };
  const createDeviceReqRowEntries = (items) => {
    if (items) {
      return items.map((item, index) => ({
        FhirId: item.FhirId,
        DeviceRequest: item.DeviceRequest,
        Code: item.Code,
        Intent: item.Intent,
        Requester: item.Requester,
        AuthoredOn: new Date(item.AuthoredOn).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        Status: item.Status,
      }));
    } else {
      return [];
    }
  };
  const createEncounterRowEntries = (items) => {
    if (items) {
      return items.map((item, index) => ({
        FhirId: item.FhirId,
        ClassCodeDisplay: item.ClassCodeDisplay,
        ClassCode: item.ClassCode,

        PeriodStart: new Date(item.PeriodStart).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),

        PeriodEnd: new Date(item.PeriodEnd).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),

        TypeCode: item.TypeCode,
        TypeDisplay: item.TypeDisplay,
        Status: item.Status,
      }));
    } else {
      return [];
    }
  };

  const createMedReqRowEntries = (items) => {
    if (items) {
      return items.map((item, index) => ({
        FhirId: item.FhirId,
        MedicationRequest: item.MedicationRequest,
        Code: item.Code,
        Intent: item.Intent,
        Requester: item.Requester,
        AuthoredOn: new Date(item.AuthoredOn).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        Status: item.Status,
      }));
    } else {
      return [];
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "green";
      case "Pending":
        return "#F7CB73";
      case "Error":
        return "red";
      case "Rejected":
        return "#D9512C";
      case "Draft":
        return "grey";
      default:
        return "grey";
    }
  };

  const createServiceReqRowEntries = (items) => {
    if (items) {
      return items.map((item, index) => ({
        FhirId: item.FhirId,
        ServiceRequest: item.ServiceRequest,
        Code: item.Code,
        // Intent: item.Intent,
        Requester: item.Requester,
        AuthoredOn: new Date(item.AuthoredOn).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        Status: (
          <Chip
            label={item.Status}
            sx={{
              backgroundColor: getStatusColor(item.Status),
              color: "white",
              // fontWeight: "bold",
            }}
          />
        ),
        Status_type: item.Status,
        Message: item.Message,
      }));
    } else {
      return [];
    }
  };

  const handlePageChange = (table, page, rowsPerPage) => {
    handleEncounterServices(table, page, rowsPerPage, resData.total);
  };

  useEffect(() => {
    handleEncounterServices("Encounter");
  }, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          height: "75vh",
          "@media (max-width: 600px)": {
            flexDirection: "column",
            height: "auto",
          },
        }}
      >
        <Tabs
          orientation={isSmallScreen ? "horizontal" : "vertical"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: isSmallScreen ? "none" : 1,
            borderBottom: isSmallScreen ? 1 : "none",
            borderColor: "#9e9e9e69",
            width: isSmallScreen ? "100%" : "15%",
            backgroundColor: isSmallScreen ? "transparent" : "transparent",
          }}
        >
          <Tab
            label="Encounter"
            {...a11yProps(0)}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: "1rem",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#1976d21f",
              }
            }}
            onClick={() => {
              handleEncounterServices("Encounter");
              setTab("Encounter");
              setResData([]);
            }}
          />
          <Tab
            label="Appointment"
            {...a11yProps(1)}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: "1rem",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#1976d21f",
              }
            }}
            onClick={() => {
              handleEncounterServices("Appointment");
              setTab("Appointment");
              setResData([]);
            }}
          />
          <Tab
            label="Device Request"
            {...a11yProps(2)}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: "1rem",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#1976d21f",
              }
            }}
            onClick={() => {
              handleEncounterServices("DeviceRequest");
              setTab("DeviceRequest");
              setResData([]);
            }}
          />
          <Tab
            label="Medication Request"
            {...a11yProps(3)}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: "1rem",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#1976d21f",
              }
            }}
            onClick={() => {
              handleEncounterServices("MedicationRequest");
              setTab("MedicationRequest");
              setResData([]);
            }}
          />
          <Tab
            label="Service Request"
            {...a11yProps(4)}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: "1rem",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#1976d21f",
              }
            }}
            onClick={() => {
              handleEncounterServices("ServiceRequest");
              setTab("ServiceRequest");
              setResData([]);
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0} sx={{ height: "100vh" }}>
          <Grid container rowSpacing={1}>
            <Grid
              container
              item
              xs={12}
              sx={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#ABD9D9",
                  color: "white",
                  "&:hover": {
                    borderColor: "#ABD9D9",
                  },
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Add Encounter
              </Button>
            </Grid>
            <Grid item sx={{ width: "100%", height: "100vh" }}>
              <EnhancedTable
                headers={EncounterHeaders}
                rows={createEncounterRowEntries(resData?.data)}
                page={resData?.currentPage}
                totalPages={resData?.totalPages}
                onChange={(page, rowsPerPage) =>
                  handlePageChange("Encounter", page, rowsPerPage)
                }
                loading={loading}
                tableHeight="75vh"
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EnhancedTable
            headers={AppointmentHeaders}
            rows={createAppointmentRowEntries(resData?.data)}
            page={resData?.currentPage}
            totalPages={resData?.totalPages}
            onChange={(page, rowsPerPage) =>
              handlePageChange("Appointment", page, rowsPerPage)
            }
            loading={loading}
            tableHeight="80vh"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EnhancedTable
            headers={DeviceReqHeaders}
            rows={createDeviceReqRowEntries(resData?.data)}
            page={resData?.currentPage}
            totalPages={resData?.totalPages}
            onChange={(page, rowsPerPage) =>
              handlePageChange("DeviceRequest", page, rowsPerPage)
            }
            loading={loading}
            tableHeight="80vh"
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EnhancedTable
            headers={MedReqHeaders}
            rows={createMedReqRowEntries(resData?.data)}
            page={resData?.currentPage}
            totalPages={resData?.totalPages}
            onChange={(page, rowsPerPage) =>
              handlePageChange("MedicationRequest", page, rowsPerPage)
            }
            loading={loading}
            tableHeight="80vh"
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <EnhancedTable
            headers={ServiceReqHeaders}
            rows={createServiceReqRowEntries(resData?.data)}
            page={resData?.currentPage}
            totalPages={resData?.totalPages}
            onChange={(page, rowsPerPage) =>
              handlePageChange("ServiceRequest", page, rowsPerPage)
            }
            onRowClick={(row) => {
              setOpenPopup(true);
              setSelectedRowData(row);
            }}
            loading={loading}
            tableHeight="80vh"
          />
        </TabPanel>
      </Box>
      {open && <AddEncounterModal open={open} setOpen={setOpen} />}
      {openPopup && selectedRowData.Status_type !== "Draft" && (
        <MessagePopUp
          open={openPopup}
          setOpen={setOpenPopup}
          data={selectedRowData}
          resourceType={tab}
        />
      )}
    </>
  );
}
