import MuiSnackbar from "@mui/material/Snackbar"
import Alert, { AlertProps } from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"


const Snackbar = (props) => {
  const { openSnackbar, setOpenSnackbar } = props

  return (
    <MuiSnackbar
      open={openSnackbar?.open}
      autoHideDuration={3000}
      onClose={(e, reason) => {
        if (reason === "clickaway") {
          return
        }
        setOpenSnackbar({
          open: false,
          title: '',
          message: '',
          severity: 'info'
        })
      }}
      message="hi"
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <Alert
        variant="filled"
        severity={openSnackbar?.severity}
        sx={{ borderRadius: "8px", textTransform: "capitalize" }}
      >
        {openSnackbar?.title &&
          <AlertTitle>{openSnackbar?.title}</AlertTitle>
        }
        {openSnackbar?.message}
      </Alert>
    </MuiSnackbar>
  )
}
export default Snackbar
