import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ID_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "../util/constants";
import { useIdleTimer } from "react-idle-timer";
import axios from "axios";
import AlertDialogSlide from "../util/AlertDialog";
import { refreshToken } from "../api/api";

const timeout = 10 * 60 * 1000; // 10 min inactive time
const promptBeforeIdle = 2 * 60 * 1000; // popup 2min before timeout
// const TOKEN_EXPIRY_TIME = 3 * 60 * 1000;
// const REMINDER_TIME = 1 * 60;

function PageLayout() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const id_token = Cookies.get(ID_TOKEN_STORAGE_KEY);
  const navigate = useNavigate();
  
  const decoded_token = useMemo(() => id_token ? jwtDecode(id_token) :  null, [id_token]);


  const accessTokenExpiryTime = decoded_token?.exp ?? 0;

  const isExpired = useMemo(() => Math.floor(Date.now() / 1000) >= accessTokenExpiryTime, [accessTokenExpiryTime]);

  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const { getRemainingTime, activate, reset } = useIdleTimer({
    onIdle: () => {
      setState("Idle");
      handleLogout();
      setOpen(false);
    },
    onActive: () => {
      setState("Active");
      setOpen(false);
    },
    onPrompt: () => {
      setState("Prompted");
      setOpen(true);
    },
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    if (isExpired) {
      alert("Session expired. Please log in again.");
      handleLogout();
    }
  }, [isExpired]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const now = Math.floor(Date.now() / 1000);
  //     const timeBeforeExpiry = accessTokenExpiryTime - REMINDER_TIME;
  //     if (now >= timeBeforeExpiry && state === "Active") {
  //       refreshToken();
  //       reset();
  //     }
  //   }, 1000 * 60); // Check every minute

  //   return () => clearInterval(interval);
  // }, [accessTokenExpiryTime, state, reset]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStay = useCallback(() => {
    setOpen(false);
    activate();
  },[activate])

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove(ACCESS_TOKEN_STORAGE_KEY);
    Cookies.remove(REFRESH_TOKEN_STORAGE_KEY);
    Cookies.remove(ID_TOKEN_STORAGE_KEY);
    navigate("/");
  },[]);
  
  return (
    <>
      {isLoggedIn ? (
        <>
          <Outlet />
          {open && (
            <AlertDialogSlide
              open={open}
              setOpen={setOpen}
              remaining={remaining}
              handleStay={handleStay}
              handleLogout={handleLogout}
              timeout = {timeout}
            />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default PageLayout;

