import React from "react";
import Header from "../components/header/Header";
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import DashboardContent from "../components/dashboard/DashboardContent";

const Dashboard = () => {

  return (
    <Header
      children={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <DashboardContent />
          <Footer />
        </Box>
      }
    />
  );
};

export default Dashboard;
