import axios from "axios"
import Cookies from "js-cookie";
import { ACCESS_TOKEN_STORAGE_KEY, ID_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from "../util/constants";
const { REACT_APP_API_ENDPOINT : apiEndpoint } = process.env;

export const getAuth = async () => {
    let data;
    const loginToken = Cookies.get(ID_TOKEN_STORAGE_KEY)
    const accessToken = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)

    console.log(loginToken, accessToken)

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-ID-Token": loginToken,
            "Content-Type": "application/json",
        }
    }
    console.log(config)
    try{
        data = await axios.post(`${apiEndpoint}/users/auth`, {}, config);
        console.log(data)
        if(typeof data.data === "object" && data.status === 200) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("user", data.data.id);
            localStorage.setItem("username", data.data.name);
            localStorage.setItem("pro_id", data.data.pro_id);
            return data.data;
        } else {
            return data.data;
        }
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const fetchProcedureCodes = async () => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
  const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
  }
  try {
    data = await axios.get(`${apiEndpoint}/users/procedures/codes`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrganizationCodes = async () => {
  let data;
  const id = localStorage.getItem("user");
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
  const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
  }
  try {
    data = await axios.get(`${apiEndpoint}/users/practitionerRole/${id}`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchCrdRequests = async (id) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(`${apiEndpoint}/users/${id}`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchPatients = async (id, queryParams = '') => {
  console.log(queryParams)
    let data;
    const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    try{
        data = await axios.get(`${apiEndpoint}/users/patients/${id}${queryParams}`,{
          headers:headers
        });
        if(data.status === 200) {
            return data.data;
        } else {
            return data.statusText;
        }
    } catch (error) {
        console.log(error);
    }
}

export const fetchPatientsCoverage = async (id, memberId) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.post(`${apiEndpoint}/users/patients`, {
      id: id,
      memberId: memberId,
    }, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const patientSearch = async (id, queryParams = '') => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(`${apiEndpoint}/users/patients/search/${id}${queryParams}`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchProvider = async (id) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(`${apiEndpoint}/users/provider/${id}`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrderForm = async (id) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(`${apiEndpoint}/users/provider/order-form/${id}`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const createCrd = async (formattedRequest) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.post(`${apiEndpoint}/users/crd`, formattedRequest, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
    return {'errors': error.response.data, 'status': error.response.status};
  }
};

export const updateDecision = async (id, action) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.post(`${apiEndpoint}/users/decision/${id}`, action, {
      headers:headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchEnounterService = async (
  user,
  id,
  resource,
  page,
  rowsPerPage,
  totalPages = null
) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(
      `${apiEndpoint}/users/encounter-start/${user}/${id}/${resource}?page=${page}&limit=${rowsPerPage}&total=${totalPages}`, {
        headers: headers
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchEnounterCodes = async () => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.get(`${apiEndpoint}/users/encounter/codes`, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchFhirContext = async (req_body) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.post(`${apiEndpoint}/users/task-fhirContext`, req_body, {
      headers: headers
    });
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};

export const AddNewEncounter = async (user_id, res_body) => {
  let data;
  const token = Cookies.get(ACCESS_TOKEN_STORAGE_KEY)
    const  headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  try {
    data = await axios.post(
      `${apiEndpoint}/users/create-encounter/${user_id}`,
      res_body,
      {
        headers:headers
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return data.statusText;
    }
  } catch (error) {
    console.log(error);
  }
};


export const refreshToken = async () => {
  console.log("token refresh");
  const now = new Date();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  console.log(minutes, seconds);
  try {
      const refreshToken = Cookies.get(REFRESH_TOKEN_STORAGE_KEY);
      const client_id = process.env.REACT_APP_EPA_CLIENT_ID
      const client_secret = process.env.REACT_APP_EPA_CLIENT_SECRET
      const combined = `${client_id}:${client_secret}`
      const encoded_bytes = btoa(combined);
      // const encoded_string = base64_decode(encoded_bytes);
      // console.log(encoded_bytes, encoded_string)
      const token_data = {
        "refresh_token": refreshToken,
        "grant_type": "refresh_token",
    }
    const token_headers = {
        "Authorization": `Basic ${encoded_bytes}`,
        "Content-Type": "application/x-www-form-urlencoded",
    }
      const data = {
        "refresh_token": refreshToken,
        "grant_type": "refresh_token",
        'client_id': client_id,
        'client_secret': client_secret,
      }

      const headers = {"Content-Type": "application/x-www-form-urlencoded"}

      const response = await axios.post(process.env.REACT_APP_ACCESS_TOKEN_URL, token_data, {
        headers: token_headers,
      });
      console.log("r", response.data);
      // Cookies.set(ACCESS_TOKEN_STORAGE_KEY, response.data.access_token);
      // Cookies.set(REFRESH_TOKEN_STORAGE_KEY, response.data.refresh_token);
      // localStorage.setItem('expire_in', Math.floor(Date.now() / 1000) + 3 * 60);

      console.log('Token refreshed successfully.');
    } catch (error) {
      console.error('Error refreshing token:', error);
    } 
}
