import { Box, Typography } from "@mui/material";
import React from "react";
import landingimage from '../../assets/landing-img.png';
import { ReactComponent as DoctorIcon } from "../../assets/doctor_logo.svg";
import { ReactComponent as GroupIcon } from "../../assets/Group.svg";
import { ReactComponent as HeartIcon } from "../../assets/heart.svg";

const LoginBody = () => {

    return (
        <Box sx={{pt: '10%', pl: '10%', display: 'flex', flexDirection: "row", gap: 2}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3}}>
                <Typography variant="h1" sx={{ display: "flex", color: "white", fontFamily: "Outfit", fontWeight: 600, fontSize: 70 }}>
                    Electronic Prior Authorization
                </Typography>
                <Typography variant="h6" sx={{ display: "flex", color: "white", fontFamily: "Outfit", letterSpacing: 7}}>Authorization done in few simple steps</Typography>
            </Box>
            {/* <Box sx={{display: "flex",
                flexDirection: 'row',
                gap: 10,
                // flexWrap: "wrap",
                justifyContent: "start",
                alignItems: "center"}}>
              <DoctorIcon />
              <Box sx={{display: 'grid', gap: 5}}>
                <GroupIcon />
                <HeartIcon />
              </Box>
            </Box> */}
            <Box sx={{display: "flex",
                flexDirection: 'row',
                gap: 10,
                // flexWrap: "wrap",
                justifyContent: "start",
                alignItems: "center"}}>
              <Box sx={{
                // background: "red",
                height: "400px",
                width: "500px",
                mt: -7
              }}>
                <img src={landingimage} alt='image' width={"100%"} height={"100%"} />
              </Box>
            </Box>
        </Box>
    )

}

export default LoginBody;