import { Avatar, Badge, Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as BackArrow } from "../../assets/Back.svg";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from "react-router-dom";
import RightMenu from "../RightMenu";

const DashboardHeader = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', pt: 5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <BackArrow />
                </IconButton>
                <Typography variant="h6" sx={{ letterSpacing: 7 }}>ePA | Dashboard</Typography>
            </Box>
            <RightMenu />
        </Box>
    )

}

export default DashboardHeader;