import React, { useState } from "react";
import { Box } from "@mui/material";
import PriorAuthHeader from "./PriorAuthHeader";
import PriorAuthBody from "./PriorAuthBody";

const PriorAuthContent = (props) => {

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
            <PriorAuthHeader/>
            <PriorAuthBody />
        </Box>
    )

}

export default PriorAuthContent;