import {
  Box,
  Chip,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

function EnhancedTableHead(props) {
  const {
    headers,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    setPage,
    setQuery,
    rowsPerPage,
    setOrderBy,
    open,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10,
        width: "100%",
        // background: "#2A2A65",
        background: "#000053",
        // background: "#8d8dda"
      }}
    >
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            // sx={{ paddingLeft: headCell.paddingLeft ? headCell.paddingLeft : 'none' }}
          >
            <Box sx={{ display: "grid", gap: 1 }}>
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              > */}
              <Chip
                label={
                  <Typography variant="tableHeader">
                    {headCell.label}
                  </Typography>
                }
                sx={{ p: 1, backgroundColor: "rgba(61, 61, 166, 0.6)" }}
              />
              {/* <Typography variant="tableHeader">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
              {/* </TableSortLabel> */}
              {/* {headCell.searchable && <TextField
                id={headCell.id}
                defaultValue=""
                size="small"
                placeholder='Search'
                sx={{width: headCell.width}}
                onChange={(e) => handleChange(e, headCell.id)}
                />} */}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
